import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Field, Formik } from 'formik';
import _ from 'lodash';

import { Typography } from 'pages/Walkthrough/components';
import NewSchoolFields from 'pages/Walkthrough/components/RolloutForm/components/NewSchoolFields';
import SignupStepper from 'pages/Walkthrough/components/SignupStepper';
import { colors } from 'theme/palette';

import Button from 'components/Button';
import InputField from 'components/InputField';
import SearchFilter from 'components/SearchFilter';

import { ReactComponent as InfoIcon } from './assets/info.svg';
import { INITIAL_VALUES, VALIDATION_SCHEMA } from './constants';
import styles from './styles.module.scss';
import { getSchoolFilterOptions, getSchoolOptionLabel } from './utils';

const USPhoneInput = ({ form, field, className, ...rest }) => {
  const handleChange = (e) => {
    form.setFieldValue(field.name, e);
  };

  return (
    <PhoneInput
      country={'us'}
      name={field.name}
      value={field.value}
      onChange={handleChange}
      {...rest}
      className={styles.phoneInput}
    />
  );
};

const IsSchoolAdminCheckbox = ({ form, field, className, ...rest }) => {
  const handleChange = () => {
    form.setFieldValue(field.name, !field.value);
  };

  return (
    <Switch
      name={field.name}
      checked={field.value}
      onChange={handleChange}
      {...rest}
    />
  );
};

const TeacherDataForm = ({
  schools,
  states,
  selectedWalkthroughSchool,
  setSelectedWalkthroughSchool,
  setSelectedState,
  selectedOrganization,
  setSelectedOrganization,
  isCompletingProfile,
  onNextClick,
  initalPreferredName,
  showStepper = true
}) => {
  const [newSchoolFieldsVisible, setNewSchoolFieldsVisible] = useState(false);

  const onSchoolChange = ({ school, setFieldValue }) => {
    if (typeof school === 'string') {
      setSelectedWalkthroughSchool({
        id: null,
        name: school
      });
      setFieldValue('schoolId', null);
      setNewSchoolFieldsVisible(true);
    } else if (school && school.newSchool) {
      // Create a new value from the user input
      setSelectedWalkthroughSchool({
        id: null,
        name: school.inputValue
      });
      setFieldValue('schoolId', null);
      setNewSchoolFieldsVisible(true);
    } else {
      setSelectedWalkthroughSchool(school);
      setFieldValue('schoolId', _.get(school, 'id', null));
      setNewSchoolFieldsVisible(false);
    }
  };

  return (
    <Formik
      initialValues={{
        ...INITIAL_VALUES,
        preferredUserName: initalPreferredName
      }}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onNextClick}
    >
      {({ values, dirty, errors, setFieldValue }) => {
        const disableSchools =
          _.has(errors, 'stateAbbreviation') ||
          _.isEmpty(values.stateAbbreviation);

        return (
          <>
            {showStepper && (
              <div className={styles.stepperWrapper}>
                <div className={styles.controlButton} />
                <SignupStepper currentStepIndex={0} />
                <Button
                  color="pink"
                  className={styles.controlButton}
                  onClick={() => onNextClick(values)}
                  disabled={!_.isEmpty(errors) || !dirty || isCompletingProfile}
                >
                  Import classes
                </Button>
              </div>
            )}

            <div className={styles.inputContainer}>
              <Typography
                variant="H-TEXT-3"
                color={colors.blue1}
                className={styles.inputLabel}
              >
                Students call me
              </Typography>
              <Field
                fullWidth
                type="text"
                name="preferredUserName"
                placeholder="Mr. T"
                className={styles.textField}
                component={InputField}
                variant="underlined"
                error={_.has(errors, 'preferredUserName')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled className={styles.icon}>
                        {(errors.preferredUserName || !dirty) && (
                          <CheckCircleRoundedIcon fontSize="small" />
                        )}
                        {!(errors.preferredUserName || !dirty) && (
                          <CheckCircleRoundedIcon
                            fontSize="small"
                            style={{ color: colors.green3 }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <div className={styles.inputContainer}>
                <Typography
                  variant="H-TEXT-3"
                  color={colors.blue1}
                  className={styles.inputLabel}
                >
                  State
                </Typography>
                <Field
                  type="text"
                  name="stateAbbreviation"
                  autoComplete
                  component={SearchFilter}
                  placeholder="State"
                  options={states}
                  getOptionLabel={(state) =>
                    `${state.name} (${state.abbreviation})`
                  }
                  onChange={(e, state) => {
                    // Make sure if the user selects state, school and then changes state -> the school name is cleared
                    // If the user created a new school, there is no need to clear the school name (The user may have clicked on the wrong state by mistake)
                    if (!newSchoolFieldsVisible) {
                      setSelectedWalkthroughSchool(null);
                      setFieldValue('schoolId', null);
                      setNewSchoolFieldsVisible(false);
                    }

                    // Set selected state
                    setFieldValue('stateAbbreviation', state.abbreviation);
                    setSelectedState(state.abbreviation);
                  }}
                />
              </div>
              {/* NOTE: This field's implementation is highly influenced by the official Material UI documentation:
            https://material-ui.com/components/autocomplete/#creatable
      */}
              <div className={styles.inputContainer}>
                <Typography
                  variant="H-TEXT-3"
                  color={colors.blue1}
                  className={styles.inputLabel}
                >
                  My school
                </Typography>
                <Field
                  type="text"
                  name="schoolId"
                  disabled={disableSchools}
                  freeSolo
                  autoComplete
                  selectOnFocus
                  clearOnBlur
                  disableListWrap
                  component={SearchFilter}
                  placeholder={
                    disableSchools ? 'Enter State' : 'Enter School Name'
                  }
                  options={schools}
                  value={selectedWalkthroughSchool}
                  getOptionLabel={(school) => getSchoolOptionLabel({ school })}
                  renderOption={(school) =>
                    _.isEmpty(school.city)
                      ? school.name
                      : `${school.name} (${school.city})`
                  }
                  filterOptions={(options, params) =>
                    getSchoolFilterOptions({ options, params })
                  }
                  onChange={(event, school) =>
                    onSchoolChange({ school, setFieldValue })
                  }
                />
              </div>
              <div className={styles.inputContainer}>
                <Typography
                  variant="H-TEXT-3"
                  color={colors.blue1}
                  className={styles.inputLabel}
                >
                  Phone number(optional)
                  <div className={styles.tooltipWrapper}>
                    <Tooltip
                      classes={{ tooltip: styles.tooltipInfo }}
                      title={
                        <Typography>
                          <b>Why do we ask for your phone number?</b> We
                          occasionally send SMS nudges—quick tips, reminders,
                          and insights to support your teaching. Opt out
                          anytime, no spam!
                        </Typography>
                      }
                      placement="top"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                </Typography>
                <Field
                  type="text"
                  name="phoneNumber"
                  component={USPhoneInput}
                />
              </div>
              <div className={styles.inputContainer}>
                <Typography
                  variant="H-TEXT-3"
                  color={colors.blue1}
                  className={styles.inputLabel}
                >
                  <Field
                    type="checkbox"
                    name="isSchoolAdmin"
                    component={IsSchoolAdminCheckbox}
                  />
                  I'm an admin
                </Typography>
              </div>
            </div>
            {newSchoolFieldsVisible && (
              <NewSchoolFields
                dirty={dirty}
                errors={errors}
                setFieldValue={setFieldValue}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
              />
            )}

            {!showStepper && (
              <Button
                fullWidth
                color="pink"
                onClick={() => onNextClick(values)}
                disabled={!_.isEmpty(errors) || !dirty}
                className={styles.submitButton}
              >
                Next
              </Button>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default TeacherDataForm;
