import React from 'react';

import { colors } from 'theme/palette';
import { AssignmentTaskValueTypes } from 'utils/constants';

import Typography from 'components/Typography';

import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as CheckMinusIcon } from './assets/check_minus.svg';
import { ReactComponent as CheckPlusIcon } from './assets/check_plus.svg';
import { ReactComponent as CorrectIcon } from './assets/correct.svg';
import { ReactComponent as IncorrectIcon } from './assets/incorrect.svg';
import { ReactComponent as TextIcon } from './assets/text.svg';
import styles from './styles.module.scss';

const AssignmentTaskValueTypeIcon = ({ task, color }) => {
  if (task.value_type === AssignmentTaskValueTypes.CHECK_GRADE) {
    return {
      0: <CheckIcon fill={color || colors.blue5} />,
      '-1': <CheckMinusIcon fill={color || colors.blue5} />,
      1: <CheckPlusIcon fill={color || colors.blue5} />
    }[task.value];
  }
  if (task.value_type === AssignmentTaskValueTypes.CHECKBOX) {
    return {
      0: <IncorrectIcon fill={color || colors.blue5} />,
      1: <CorrectIcon fill={color || colors.blue5} />
    }[task.value];
  }
  if (task.value_type === AssignmentTaskValueTypes.TEXT) {
    return <TextIcon fill={color || colors.blue5} />;
  }
  if (task.value_type === AssignmentTaskValueTypes.RUBRIC_A_TO_F) {
    return (
      <div className={styles.numberObjective}>
        <Typography variant="S-TEXT-1" color={color || colors.blue1}>
          {task.raw_value}
        </Typography>
      </div>
    );
  }
  return (
    <div className={styles.numberObjective}>
      <Typography variant="S-TEXT-1" color={color || colors.blue1}>
        {task.value}
      </Typography>
    </div>
  );
};

export default AssignmentTaskValueTypeIcon;
