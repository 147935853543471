export const BASE_BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
export const BASE_URL = `${BASE_BACKEND_URL}/api`;

export const EDLIGHT_MAIN_DOMAIN_URL = 'https://www.edlight.com/';

export const LOGIN_URL = '/login';
export const SIGN_UP_URL = '/sign-up';
export const STUDENT_SIGN_UP_URL = `${SIGN_UP_URL}/student`;
export const WEBCAM_PLAYGROUND_URL = '/webcam';
export const MOBILE_APP_URL = `/mobile-app`;

export const FREE_TRIAL_BLOCKER_URL = '/free-trial-blocker';
export const ACTIVATION_BLOCKER_URL = '/activation-blocker';
export const ROSTERING_ERROR_URL = '/rostering-error';
export const ROSTERING_LOADER_URL = '/rostering-loader';

export const TEACHERS_URL = '/teacher';
export const TEACHERS_ASSIGNMENTS_URL = `${TEACHERS_URL}/assignments`;
export const TEACHERS_PERMISSION_DENIED_URL = `${TEACHERS_URL}/permission-denied/`;

export const TEACHERS_CAPTURE_URL = `${TEACHERS_URL}/capture`;
export const TEACHERS_BULK_UPLOAD_URL = `${TEACHERS_URL}/bulk-upload`;
export const TEACHERS_BULK_UPLOAD_WORK_UPLOAD_URL = `${TEACHERS_BULK_UPLOAD_URL}/upload`;
export const TEACHERS_BULK_UPLOAD_WORK_UPLOAD_AND_QR_URL = `${TEACHERS_BULK_UPLOAD_URL}/upload-and-qr`;
export const TEACHERS_BULK_UPLOAD_NAME_REGION_SELECT_URL = `${TEACHERS_BULK_UPLOAD_URL}/:bulkUploadId/region-select`;
export const TEACHERS_BULK_UPLOAD_ASSIGN_ASSIGNMENT_URL = `${TEACHERS_BULK_UPLOAD_URL}/:bulkUploadId/assign-assignment`;
export const TEACHERS_BULK_UPLOAD_PAGE_CONFIRMATION_URL = `${TEACHERS_BULK_UPLOAD_URL}/:bulkUploadId/page-confirm`;
export const TEACHERS_BULK_UPLOAD_ASSIGN_AND_CONFIRMATION_URL = `${TEACHERS_BULK_UPLOAD_URL}/:bulkUploadId/assign-and-confirm`;
export const TEACHERS_BULK_UPLOAD_SUBMISSIONS_MATCH_URL = `${TEACHERS_BULK_UPLOAD_URL}/:bulkUploadId/submissions-match`;
export const TEACHERS_BULK_UPLOAD_SUCCESS_URL = `${TEACHERS_BULK_UPLOAD_URL}/:bulkUploadId/success`;
export const TEACHERS_BULK_UPLOAD_MATCH_STUDENTS_URL = `${TEACHERS_BULK_UPLOAD_URL}/:bulkUploadId/match-students`;

export const TEACHERS_STUDENTS_LIST = `${TEACHERS_URL}/students`;
export const TEACHERS_STUDENT_PORTFOLIO_URL = `${TEACHERS_URL}/students/:id`;
export const TEACHERS_STUDENT_PORTFOLIO_ASSIGNMENT_DETAILS_URL = `${TEACHERS_URL}/students/:studentId/assignment/:assignmentId`;

export const TEACHERS_ASSIGNMENTS_DETAIL_URL = `${TEACHERS_ASSIGNMENTS_URL}/:trackerId`;
export const TEACHERS_ASSIGNMENTS_FEEDBACK_URL = `${TEACHERS_ASSIGNMENTS_URL}/:trackerId/feedback`;
export const TEACHERS_ASSIGNMENTS_LIST_URL = `${TEACHERS_ASSIGNMENTS_URL}`;
export const TEACHERS_ASSIGNMENTS_RESULTS_URL = `${TEACHERS_ASSIGNMENTS_URL}/:trackerId/results`;
export const TEACHERS_ASSIGNMENTS_STUDENTS_STATUS_URL = `${TEACHERS_ASSIGNMENTS_URL}/:sectionId/students-status`;

export const TEACHERS_SUBSCRIPTIONS_URL = `${TEACHERS_URL}/subscriptions`;

export const TEACHERS_SUBSCRIPTIONS_DASHBOARD_URL = `${TEACHERS_SUBSCRIPTIONS_URL}`;
export const TEACHERS_SUBSCRIPTIONS_CHANGE_PLAN_URL = `${TEACHERS_SUBSCRIPTIONS_URL}/change-plan`;

export const TEACHERS_TEAM_DETAIL_URL = `${TEACHERS_URL}/team/:teamId`;

export const TEACHERS_MEETINGS_LIST_URL = `${TEACHERS_URL}/meetings`;

export const TEACHERS_MEETING_FEED_URL = `${TEACHERS_URL}/meetings/:invitationIdentifier`;
export const TEACHERS_MEETING_SORT_URL = `${TEACHERS_URL}/meetings/:invitationIdentifier/sort/`;

export const TEACHERS_INSIGHTS_V2_BASE_URL = `${TEACHERS_URL}/insights`;
export const TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL = `${TEACHERS_INSIGHTS_V2_BASE_URL}/assignments/`;
export const TEACHERS_INSIGHTS_V2_OVERVIEW_URL = `${TEACHERS_INSIGHTS_V2_BASE_URL}/overview/:trackerInstanceSectionId`;

export const STUDENTS_URL = '/student';
export const STUDENTS_ASSIGNMENTS_URL = `${STUDENTS_URL}/assignments`;
export const STUDENTS_NOTIFICATIONS_URL = `${STUDENTS_URL}/notifications`;

export const STUDENTS_ASSIGNMENTS_LIST_URL = `${STUDENTS_ASSIGNMENTS_URL}`;
export const STUDENTS_ASSIGNMENTS_DETAIL_URL = `${STUDENTS_ASSIGNMENTS_URL}/:assignmentId`;
export const STUDENTS_ASSIGNMENTS_OVERVIEW_URL = `${STUDENTS_ASSIGNMENTS_URL}/:assignmentId/overview`;
export const STUDENTS_ASSIGNMENTS_REDIRECT_URL = `${STUDENTS_ASSIGNMENTS_URL}/:trackerInstanceId/redirect`;

export const STUDENTS_NOTIFICATIONS_LIST_URL = `${STUDENTS_NOTIFICATIONS_URL}`;

export const MAGIC_LOGIN_URL = `/magic-login/:token`;
export const SCHOOL_LOGIN_URL = `/school-login/:identifier`;

export const TRY_URL = '/try';
export const WALKTHROUGH_URL = '/walkthrough';
export const TEACHER_SIGN_UP_URL = `${WALKTHROUGH_URL}/teacher`;
export const WALKTHROUGH_OVERVIEW_URL = `${WALKTHROUGH_URL}/overview`;
export const WALKTHROUGH_SIGN_UP_WITH_PASSWORD = `${WALKTHROUGH_URL}/create-account`;
export const WALKTHROUGH_CREATE_PROFILE_URL = `${WALKTHROUGH_URL}/create-profile`;
export const WALKTHROUGH_ROLLOUT_URL = `${WALKTHROUGH_URL}/rollout`;

export const WALKTHROUGH_CLEVER_LIBRARY_ROLLOUT_URL = `${WALKTHROUGH_URL}/clever/rollout`;

export const STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL = `${STUDENTS_URL}/portfolio`;
export const STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL = `${STUDENTS_URL}/portfolio/standards`;
export const STUDENTS_MY_PORTFOLIO_DATE_VIEW_URL = `${STUDENTS_URL}/portfolio/date`;
export const STUDENTS_MY_PORTFOLIO_INCOMPLETE_ASSIGNMENTS_VIEW_URL = `${STUDENTS_URL}/portfolio/incomplete-assignments`;

export const PATHWAYS_URL = '/pathways';
export const PATHWAYS_START_URL = `${PATHWAYS_URL}/start`;
export const PATHWAYS_PROFILE_JOURNEY_URL = `${PATHWAYS_URL}/profile/journey`;
export const PATHWAYS_PROFILE_PERSONAL_GOALS_URL = `${PATHWAYS_URL}/profile/personal-goals`;
export const PATHWAYS_GATHERING_RESULTS_URL = `${PATHWAYS_URL}/gathering/results`;
export const PATHWAYS_DAILY_PROGRESS_URL = `${PATHWAYS_URL}/daily-progress`;
export const PATHWAYS_RESULTS_URL = `${PATHWAYS_URL}/results`;
export const PATHWAYS_INSIGHTS_URL = `${PATHWAYS_URL}/insights`;
export const PATHWAYS_INSIGHTS_URL_WITH_ASSIGNMENT = `${PATHWAYS_URL}/insights/:sectionId/:schoolId/:organizationId`;
export const PATHWAYS_TODAY_LESSON_URL = `${PATHWAYS_URL}/today-lesson`;
export const PATHWAYS_CHATBOT_URL = `${PATHWAYS_URL}/chatbot`;
export const PATHWAYS_PROGRESS_URL = `${PATHWAYS_URL}/progress`;
export const PATHWAYS_COMMUNITY_URL = `${PATHWAYS_URL}/community`;

export const FAMILY_URL = '/family';
export const FAMILY_PLANNER_URL = `${FAMILY_URL}/planner`;
export const FAMILY_HOME_URL = `${FAMILY_URL}/home`;
export const FAMILY_NOTIFICATIONS_URL = `${FAMILY_URL}/notifications`;
export const FAMILY_RESOURCES_URL = `${FAMILY_URL}/resources`;
export const FAMILY_ASSIGNMENTS_DETAIL_URL = `${FAMILY_URL}/students/:studentId/assignments/:assignmentId`;
