import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import classnames from 'classnames';
import {
  EDLIGHT_MAIN_DOMAIN_URL,
  SIGN_UP_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL,
  WALKTHROUGH_ROLLOUT_URL
} from 'config/urls';
import { Field, Formik } from 'formik';
import logo from 'images/logo-dark.svg';
import _, { get } from 'lodash';
import { createProfile, walkthroughUserAcceptTermsAndConditions } from 'sdk';

import AnonymousRequired from 'pages/Login/components/AnonymousRequired';
import { Typography, useWalkthroughUser } from 'pages/Walkthrough/components';
import { signUpWithGoogle } from 'pages/Walkthrough/components/CreateProfileForm';
import LayoutWithBackgroundImage from 'pages/Walkthrough/components/LayoutWithBackgroundImage';
import { colors } from 'theme/palette';
import { setFormErrors } from 'utils/forms';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { notifyErrors } from 'utils/notifications';
import { useBreakpoints } from 'utils/resizing';
import { reverse } from 'utils/urls';

import Button from 'components/Button';
import Image from 'components/Image';
import InputField from 'components/InputField';
import UserContext from 'components/UserContext';

import { ReactComponent as CleverIcon } from './assets/clever.svg';
import { ReactComponent as GoogleIcon } from './assets/google.svg';
import { initialValues, validate, validationSchema } from './constants.js';
import styles from './styles.module.scss';

const TeacherSignUp = () => {
  const { walkthroughUser } = useWalkthroughUser();

  const history = useHistory();

  const [passwordType, setPasswordType] = useState('password');
  const { setUser } = useContext(UserContext);
  const passwordShowed = passwordType === 'text';

  const hasAcceptedTermsAndConditions = get(
    history.location.state,
    'hasAcceptedTermsAndConditions',
    SIGN_UP_URL
  );

  useEffect(() => {
    if (walkthroughUser && hasAcceptedTermsAndConditions)
      walkthroughUserAcceptTermsAndConditions();
  }, [walkthroughUser, hasAcceptedTermsAndConditions]);

  useEffect(() => {
    if (walkthroughUser) {
      trackMixpanelEvent(
        walkthroughUser.id,
        `${MixpanelObject.PAGE} ${MixpanelAction.VIEW}`,
        {
          feature: 'sign up',
          view: 'start'
        }
      );
    }
  }, [walkthroughUser]);

  const handleSubmit = async ({ values, setFieldError }) => {
    const { success, data, errors } = await createProfile({
      data: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password
      }
    });

    if (success) {
      trackMixpanelEvent(
        walkthroughUser.id,
        `${MixpanelObject.FORM} ${MixpanelAction.SUBMIT}`,
        {
          feature: 'sign up',
          view: 'complete',
          step: 'create account'
        }
      );

      setUser(data);

      if (data.created || (!data.is_teacher && _.isNull(data.student_id))) {
        // Go to next rollout step
        history.push({
          pathname: reverse(WALKTHROUGH_ROLLOUT_URL),
          search: history.location.search
        });
      } else {
        // If user already has profile, redirect him to homepage
        history.push(
          data.is_teacher
            ? TEACHERS_ASSIGNMENTS_LIST_URL
            : STUDENTS_ASSIGNMENTS_LIST_URL
        );
      }
    } else {
      if (_.find(errors, ['code', 'invalid'])) {
        setFormErrors(errors, setFieldError);
      } else {
        notifyErrors(errors);
      }

      trackMixpanelEvent(
        walkthroughUser.id,
        `${MixpanelObject.PAGE} ${MixpanelAction.VIEW}`,
        {
          feature: 'sign up',
          view: 'error',
          error: errors.map((error) => error?.field)
        }
      );
    }
  };

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  return (
    <LayoutWithBackgroundImage>
      <div className={styles.page}>
        {!isMobile && (
          <a href={EDLIGHT_MAIN_DOMAIN_URL}>
            <Image src={logo} className={styles.logoSmall} alt="Logo link" />
          </a>
        )}

        <div className={styles.container}>
          <Paper elevation={0} className={styles.paper}>
            <div>
              <div className={styles.content}>
                <Image
                  src={logo}
                  className={styles.logoBig}
                  alt="Logo placeholder"
                />
                <Typography
                  variant="H-TEXT-1"
                  align="center"
                  color={colors.blue3}
                  className={styles.title}
                >
                  Sign up as an Educator
                </Typography>
                <Box>
                  <Button
                    fullWidth
                    variant="contained"
                    classes={{
                      root: styles.googleButton,
                      label: styles.googleButtonLabel,
                      startIcon: styles.googleButtonIcon
                    }}
                    startIcon={
                      <SvgIcon component={GoogleIcon} fontSize="large" />
                    }
                    noTypography
                    onClick={() => signUpWithGoogle({ history })}
                  >
                    Sign up with Google
                  </Button>
                </Box>
                <Box marginTop="16px">
                  <Link href={'https://www.clever.com'} target="_blank">
                    <Button
                      fullWidth
                      variant="contained"
                      classes={{
                        root: styles.cleverButton,
                        label: styles.cleverButtonLabel,
                        startIcon: styles.cleverButtonIcon
                      }}
                      startIcon={
                        <SvgIcon component={CleverIcon} fontSize="large" />
                      }
                      noTypography
                    >
                      Sign up with Clever
                    </Button>
                  </Link>
                </Box>
                <div className={styles.grayLine} />
                <div className={styles.signUpWithPassword}>
                  <Typography
                    variant="B-TEXT-2"
                    align="left"
                    color={colors.blue3}
                    className={styles.passwordTitle}
                  >
                    Or sign up with Email
                  </Typography>
                  <div className={styles.formContainer}>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      validate={validate}
                    >
                      {({
                        values,
                        errors,
                        setFieldValue,
                        setFieldError,
                        isSubmitting
                      }) => {
                        const submitDisabled =
                          isSubmitting ||
                          _.isEmpty(values.password) ||
                          _.isEmpty(values.email) ||
                          !_.isEmpty(errors);

                        return (
                          <form
                            method="post"
                            className={styles.formContainer}
                            onSubmit={(event) => {
                              event.preventDefault();
                              handleSubmit({ values, setFieldError });
                            }}
                          >
                            <div className={styles.names}>
                              <Field
                                type="text"
                                name="firstName"
                                variant="underlined"
                                component={InputField}
                                placeholder="First name"
                                error={_.has(errors, 'firstName')}
                                className={classnames(styles.name, {
                                  [styles.hasValue]: _.get(values, 'firstName')
                                })}
                              />

                              <Field
                                type="text"
                                name="lastName"
                                variant="underlined"
                                component={InputField}
                                placeholder="Last name"
                                error={_.has(errors, 'lastName')}
                                className={classnames(styles.name, {
                                  [styles.hasValue]: _.get(values, 'lastName')
                                })}
                              />
                            </div>

                            {errors.lastName && (
                              <Typography
                                variant="B-Text-4"
                                color={colors.pink1}
                                className={styles.error}
                              >
                                {errors.lastName}
                              </Typography>
                            )}

                            <Field
                              type="email"
                              name="email"
                              variant="underlined"
                              component={InputField}
                              placeholder="Email"
                              error={_.has(errors, 'email')}
                              className={classnames(styles.inputField, {
                                [styles.hasValue]: _.get(values, 'email')
                              })}
                            />

                            {errors.email && (
                              <Typography
                                variant="B-Text-4"
                                color={colors.pink1}
                                className={styles.error}
                              >
                                {errors.email}
                              </Typography>
                            )}

                            <Field
                              type={passwordType}
                              name="password"
                              variant="underlined"
                              component={InputField}
                              placeholder="Password"
                              error={_.has(errors, 'password')}
                              className={classnames(styles.inputField, {
                                [styles.hasValue]: _.get(values, 'password')
                              })}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      className={styles.icon}
                                      onClick={() =>
                                        setPasswordType(
                                          passwordShowed ? 'password' : 'text'
                                        )
                                      }
                                    >
                                      {!passwordShowed && (
                                        <VisibilityRoundedIcon />
                                      )}
                                      {passwordShowed && (
                                        <VisibilityOffRoundedIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            {errors.password && (
                              <Typography
                                variant="B-Text-4"
                                color={colors.pink1}
                                className={styles.error}
                              >
                                {errors.password}
                              </Typography>
                            )}
                            {/* <Typography
                    variant="B-Text-4"
                    color={colors.grey3}
                    className={styles.helperText}
                  >
                    - At least 8 characters
                    <br />
                    - At least one letter
                    <br />
                    - Not too similar to your email address
                    <br />- Not a commonly used password
                  </Typography> */}

                            <Button
                              color={submitDisabled ? 'lightGrey' : 'pink'}
                              type="submit"
                              disabled={submitDisabled}
                              className={styles.signUpButton}
                            >
                              Sign up
                            </Button>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </LayoutWithBackgroundImage>
  );
};

export default AnonymousRequired(TeacherSignUp);
