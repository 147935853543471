import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import classnames from 'classnames';
import _ from 'lodash';

import { AssignmentStatusHorizontal, Typography } from 'pages/Students/shared';
import { colors } from 'theme/palette';
import { AssignmentTaskValueTypes } from 'utils/constants';

import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as CheckMinusIcon } from './assets/check_minus.svg';
import { ReactComponent as CheckPlusIcon } from './assets/check_plus.svg';
import { ReactComponent as CorrectIcon } from './assets/correct.svg';
import { ReactComponent as IncorrectIcon } from './assets/incorrect.svg';
import { ReactComponent as TextIcon } from './assets/text.svg';
import styles from './styles.module.scss';

const StyledCheckCircleRoundedIcon = withStyles({
  root: {
    width: 14,
    height: 14,
    color: 'var(--grey-1)',
    '&.green': {
      color: 'var(--green)'
    }
  }
})(CheckCircleRoundedIcon);

const getTaskName = (valueType, value) => {
  const valueNames = {
    checkbox: {
      1: 'correct',
      0: 'incorrect'
    },
    check_grade: {
      '-1': 'check minus',
      0: 'check',
      1: 'check plus'
    }
  };

  return _.get(valueNames, `${valueType}.${value}`, '');
};

export const TaskInfo = ({ task, index, onClick, lgScreen }) => {
  const getTaskValueIcon = () => {
    if (task.value_type === AssignmentTaskValueTypes.CHECK_GRADE) {
      return {
        0: <CheckIcon fill={colors.blue5} />,
        '-1': <CheckMinusIcon fill={colors.blue5} />,
        1: <CheckPlusIcon fill={colors.blue5} />
      }[task.value];
    }
    if (task.value_type === AssignmentTaskValueTypes.CHECKBOX) {
      return {
        0: <IncorrectIcon fill={colors.blue5} />,
        1: <CorrectIcon fill={colors.blue5} />
      }[task.value];
    }
    if (task.value_type === AssignmentTaskValueTypes.TEXT) {
      return <TextIcon fill={colors.blue5} />;
    }
    if (task.value_type === AssignmentTaskValueTypes.RUBRIC_A_TO_F) {
      return (
        <div className={styles.numberObjective}>
          <Typography variant="S-TEXT-1" color={colors.blue1}>
            {task.raw_value}
          </Typography>
        </div>
      );
    }
    return (
      <div className={styles.numberObjective}>
        <Typography variant="S-TEXT-1" color={colors.blue1}>
          {task.value}
        </Typography>
      </div>
    );
  };

  const hasScore = !_.isNull(task.raw_value);
  const hasFeedback = !_.isEmpty(task.work.filter((x) => x.is_feedback));
  const hasComments = !_.isEmpty(task.comments);
  const hasAudioFeedback = !_.isEmpty(task.audio_files);

  return (
    <div className={classnames(styles.task, 'pointer')} onClick={onClick}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={styles.taskName}
      >
        <Grid item xs>
          <Typography
            variant={lgScreen ? 'H-TEXT-3' : 'S-TEXT-1'}
            color={colors.white}
          >
            {index}. {task.name}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton size="small">
            <ChevronRightRoundedIcon
              fontSize="large"
              htmlColor={colors.grey4}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={styles.taskInfo}>
        <Grid item xs={3}>
          <Typography
            variant={lgScreen ? 'S-TEXT-2' : 'S-TEXT-4'}
            color={colors.white}
          >
            STATUS
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <AssignmentStatusHorizontal
            status={task.status}
            lgScreen={lgScreen}
          />
        </Grid>
      </Grid>
      {hasScore && (
        <Grid container alignItems="center" className={styles.taskInfo}>
          <Grid item xs={3}>
            <Typography
              variant={lgScreen ? 'S-TEXT-2' : 'S-TEXT-4'}
              color={colors.white}
            >
              SCORE
            </Typography>
          </Grid>
          <Grid item container xs={9} spacing={1} alignItems="center">
            <Grid item>{getTaskValueIcon()}</Grid>
            <Grid item>
              <Typography
                variant={lgScreen ? 'S-TEXT-1' : 'S-TEXT-2'}
                display="inline"
                color={colors.white}
              >
                {getTaskName(task.value_type, task.value)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!_.isEmpty(task.criteria) && (
        <Grid
          container
          alignItems={lgScreen ? 'center' : 'flex-start'}
          className={styles.taskInfo}
        >
          <Grid item xs={3}>
            <Typography
              variant={lgScreen ? 'S-TEXT-2' : 'S-TEXT-4'}
              color={colors.white}
            >
              CRITERIA FOR
              <br />
              SUCCESS
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {_.map(task.criteria, ({ name, value }, index) => (
              <Grid container alignItems="center" spacing={1} key={index}>
                <Grid item style={{ display: 'flex' }}>
                  <StyledCheckCircleRoundedIcon
                    className={value ? 'green' : 'white'}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant={lgScreen ? 'B-Text-2' : 'B-Text-3'}
                    color={colors.white}
                  >
                    {name}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {(hasFeedback || hasComments || hasAudioFeedback) && (
        <Grid container alignItems="center" className={styles.taskInfo}>
          <Grid item xs={3}>
            <Typography
              variant={lgScreen ? 'S-TEXT-2' : 'S-TEXT-4'}
              color={colors.white}
            >
              FEEDBACK
            </Typography>
          </Grid>
          <Grid item xs={9} container spacing={2}>
            {hasFeedback && (
              <Grid item>
                <ImageRoundedIcon style={{ color: colors.blue5 }} />
              </Grid>
            )}
            {hasComments && (
              <Grid item>
                <CommentRoundedIcon style={{ color: colors.blue5 }} />
              </Grid>
            )}
            {hasAudioFeedback && (
              <Grid item>
                <VolumeUpRoundedIcon style={{ color: colors.blue5 }} />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export const LightTaskInfo = ({ task, lgScreen }) => {
  const getTaskValueIcon = () => {
    if (task.value_type === AssignmentTaskValueTypes.CHECK_GRADE) {
      return {
        0: (
          <div className={styles.roundIconContainer}>
            <CheckIcon fill={colors.white} />
          </div>
        ),
        '-1': (
          <div className={styles.roundIconContainer}>
            <CheckMinusIcon fill={colors.white} />
          </div>
        ),
        1: (
          <div className={styles.roundIconContainer}>
            <CheckPlusIcon fill={colors.white} />
          </div>
        )
      }[task.value];
    }
    if (task.value_type === AssignmentTaskValueTypes.CHECKBOX) {
      return {
        0: (
          <div className={styles.squareIconContainer}>
            <IncorrectIcon
              fill={colors.grey1}
              style={{ backgroundColor: colors.grey5 }}
            />
          </div>
        ),
        1: (
          <div
            className={styles.squareIconContainer}
            style={{ backgroundColor: colors.green }}
          >
            <CorrectIcon fill={colors.grey1} />
          </div>
        )
      }[task.value];
    }
    if (task.value_type === AssignmentTaskValueTypes.RUBRIC_ZERO_FOUR) {
      return (
        <div
          className={styles.squareIconContainer}
          style={{ backgroundColor: colors.green }}
        >
          <Typography variant="S-TEXT-2" color={colors.blue2}>
            {task.value}
          </Typography>
        </div>
      );
    }
    if (task.value_type === AssignmentTaskValueTypes.RUBRIC_A_TO_F) {
      return (
        <div
          className={styles.squareIconContainer}
          style={{ backgroundColor: colors.green }}
        >
          <Typography variant="S-TEXT-2" color={colors.blue2}>
            {task.raw_value}
          </Typography>
        </div>
      );
    }
    if (task.value_type === AssignmentTaskValueTypes.TEXT) {
      return <TextIcon fill={colors.blue5} />;
    }
    return (
      <Typography variant="S-TEXT-1" color={colors.blue2}>
        {task.value}
      </Typography>
    );
  };

  const hasScore = !_.isNull(task.raw_value);

  return (
    <div className={styles.lightTask}>
      <Grid container alignItems="center" className={styles.taskInfo}>
        <Grid item xs={3}>
          <Typography
            variant={lgScreen ? 'S-TEXT-2' : 'S-TEXT-4'}
            color={colors.grey1}
          >
            STATUS
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <AssignmentStatusHorizontal
            status={task.status}
            light={false}
            lgScreen={lgScreen}
          />
        </Grid>
      </Grid>
      {hasScore && (
        <Grid container alignItems="center" className={styles.taskInfo}>
          <Grid item xs={3}>
            <Typography
              variant={lgScreen ? 'S-TEXT-2' : 'S-TEXT-4'}
              color={colors.grey1}
            >
              SCORE
            </Typography>
          </Grid>
          <Grid item xs={9} container spacing={1} alignItems="center">
            <Grid item>{getTaskValueIcon()}</Grid>
            <Grid item>
              <Typography
                variant={lgScreen ? 'S-TEXT-1' : 'S-TEXT-2'}
                display="inline"
                color={colors.blue2}
              >
                {getTaskName(task.value_type, task.value)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!_.isEmpty(task.criteria) && (
        <Grid container alignItems="flex-start" className={styles.taskInfo}>
          <Grid item xs={3}>
            <Typography
              variant={lgScreen ? 'S-TEXT-2' : 'S-TEXT-4'}
              color={colors.grey1}
            >
              CRITERIA FOR
              <br />
              SUCCESS
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {_.map(task.criteria, ({ name, value }, index) => (
              <Grid container alignItems="center" spacing={1} key={index}>
                <Grid item style={{ display: 'flex' }}>
                  <StyledCheckCircleRoundedIcon
                    className={value ? 'green' : 'white'}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant={lgScreen ? 'B-Text-2' : 'B-Text-3'}
                    color={colors.blue2}
                  >
                    {name}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </div>
  );
};
