import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import {
  ACTIVATION_BLOCKER_URL,
  EDLIGHT_MAIN_DOMAIN_URL,
  FAMILY_PLANNER_URL,
  LOGIN_URL,
  PATHWAYS_START_URL,
  ROSTERING_ERROR_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL
} from 'config/urls';
import logo from 'images/logo-dark.svg';
import { get } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useCleverSync, useGoogleSync, useMe } from 'sdk';

import { logout } from 'pages/Teachers/shared/TeacherPageLayout/components/TopBar/sdk';
import { Typography } from 'pages/Walkthrough/components';
import LayoutWithBackgroundImage from 'pages/Walkthrough/components/LayoutWithBackgroundImage';
import { colors } from 'theme/palette';
import { bootIntercomWidget } from 'utils/integrations/intercom';
import { shutdownIntercomWidget } from 'utils/integrations/intercom';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { default as pusherManager } from 'utils/integrations/pusher';
import { useBreakpoints } from 'utils/resizing';
import { parseParams, stringifyParams } from 'utils/urls';

import Image from 'components/Image';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const RosteringLoader = () => {
  const history = useHistory();

  const searchParams = parseParams(history.location.search);

  const googleSyncId = get(searchParams, 'googleSyncId');
  const cleverSyncId = get(searchParams, 'cleverSyncId');
  const schoolId = get(searchParams, 'schoolId');
  const next = get(searchParams, 'next');

  const { data: user, isLoading } = useMe();

  const { setUser } = useContext(UserContext);

  const { data: googleSync, refetch: refetchGoogleSync } = useGoogleSync({
    googleSyncRunId: googleSyncId
  });
  const { data: cleverSync, refetch: refetchCleverSync } = useCleverSync({
    cleverSyncRunId: cleverSyncId
  });

  const shouldPollGoogle = useMemo(
    () => googleSync && googleSync.status === 'initiated',
    [googleSync]
  );
  const shouldPollClever = useMemo(
    () => cleverSync && cleverSync.status === 'initiated',
    [cleverSync]
  );

  useEffect(() => {
    if (!user) {
      return;
    }
    setUser(user);
  }, [user, setUser]);

  useEffect(() => {
    if (!isLoading && !user) {
      history.push(LOGIN_URL);
    }
    if (!user) {
      return;
    }
    if (!user.is_teacher && user.student_id) {
      history.push(STUDENTS_ASSIGNMENTS_LIST_URL);
    }
    if (!user.is_teacher && user.is_family_member) {
      history.push(FAMILY_PLANNER_URL);
    }
  }, [user, isLoading, history]);

  useEffect(() => {
    if (shouldPollGoogle) {
      const interval = setInterval(() => {
        refetchGoogleSync();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [shouldPollGoogle, refetchGoogleSync]);

  useEffect(() => {
    if (shouldPollClever) {
      const interval = setInterval(() => {
        refetchCleverSync();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [shouldPollClever, refetchCleverSync]);

  useEffect(() => {
    if (
      !shouldPollGoogle &&
      googleSync &&
      googleSync.status === 'ready' &&
      user
    ) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.FORM} ${MixpanelAction.ON_CHANGE}`,
        {
          feature: 'roster sync',
          view: 'success',
          roster_type: 'google'
        }
      );
      const timer = setTimeout(() => {
        if (user.is_pathways_user) {
          history.push({
            pathname: PATHWAYS_START_URL,
            search: stringifyParams({ source: 'google-sso' })
          });
        } else if (next) {
          history.push(next);
        } else {
          history.push({
            pathname: TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL,
            search: stringifyParams(searchParams)
          });
        }
      }, 3000);

      return () => clearTimeout(timer);
    } else if (
      !shouldPollGoogle &&
      googleSync &&
      googleSync.status === 'failed'
    ) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.FORM} ${MixpanelAction.ON_CHANGE}`,
        {
          feature: 'roster sync',
          view: 'fail',
          roster_type: 'google'
        }
      );
      const timer = setTimeout(() => {
        history.push({
          pathname: ROSTERING_ERROR_URL,
          search: stringifyParams({ schoolId })
        });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [
    googleSync,
    shouldPollGoogle,
    user,
    history,
    next,
    searchParams,
    schoolId
  ]);

  useEffect(() => {
    if (
      !shouldPollClever &&
      cleverSync &&
      cleverSync.status === 'ready' &&
      user
    ) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.FORM} ${MixpanelAction.ON_CHANGE}`,
        {
          feature: 'roster sync',
          view: 'success',
          roster_type: 'clever'
        }
      );
      const timer = setTimeout(() => {
        if (user.is_pathways_user) {
          history.push({
            pathname: PATHWAYS_START_URL,
            search: stringifyParams({ source: 'clever-sso' })
          });
        } else if (next) {
          history.push({
            pathname: next,
            search: stringifyParams({ source: 'clever-sso' })
          });
        } else if (!user.is_paid) {
          history.push({
            pathname: ACTIVATION_BLOCKER_URL,
            search: stringifyParams(searchParams)
          });
        } else {
          history.push({
            pathname: TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL,
            search: stringifyParams(searchParams)
          });
        }
      }, 3000);
      return () => clearTimeout(timer);
    } else if (
      !shouldPollClever &&
      cleverSync &&
      cleverSync.status === 'failed'
    ) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.FORM} ${MixpanelAction.ON_CHANGE}`,
        {
          feature: 'roster sync',
          view: 'fail',
          roster_type: 'clever'
        }
      );
      const timer = setTimeout(
        () =>
          history.push({
            pathname: ROSTERING_ERROR_URL,
            search: stringifyParams({ schoolId, clever: true })
          }),
        3000
      );
      return () => clearTimeout(timer);
    }
  }, [
    cleverSync,
    shouldPollClever,
    user,
    history,
    next,
    searchParams,
    schoolId
  ]);

  useEffect(() => {
    bootIntercomWidget();
    return () => shutdownIntercomWidget();
  }, []);

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  const logoutAndRedirect = async () => {
    shutdownIntercomWidget();
    pusherManager.disconnect();
    await logout().then(() => {
      history.push(LOGIN_URL);

      if (process.env.REACT_APP_MIXPANEL_KEY) {
        mixpanel.reset();
      }
    });
  };

  return (
    <LayoutWithBackgroundImage>
      <div className={styles.page}>
        {!isMobile && (
          <a href={EDLIGHT_MAIN_DOMAIN_URL}>
            <Image src={logo} className={styles.logoSmall} alt="Logo link" />
          </a>
        )}

        <MuiButton className={styles.logInButton} onClick={logoutAndRedirect}>
          <Typography variant="H-TEXT-2" color={colors.blue2}>
            Log Out
          </Typography>
        </MuiButton>

        <div className={styles.container}>
          <Paper elevation={0} className={styles.paper}>
            <div>
              <div className={styles.content}>
                <Image
                  src={logo}
                  className={styles.logoBig}
                  alt="Logo placeholder"
                />
                <CircularProgress size={80} />
                <Typography
                  variant="H-Text-1"
                  color={colors.pink1}
                  className={styles.title}
                >
                  Hang tight! We're getting everything set up so you have the
                  most up-to-date rosters. This won't take long!
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </LayoutWithBackgroundImage>
  );
};

export default RosteringLoader;
