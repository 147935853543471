import React from 'react';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import SvgIcon from '@material-ui/core/SvgIcon';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const ConfirmPages = ({
  bulkUpload,
  focusedImage,
  rotationCount,
  focusedPageNumber,
  setFocusedPageNumber,
  totalPageNumbers,
  setRotationCount,
  user
}) => {
  const handleRotationClick = () => {
    setRotationCount((prevCount) => (prevCount + 1) % 4);
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'capture',
        view: 'assignment',
        button: 'rotate'
      }
    );
  };

  return (
    <div className={styles.confirmAssignmentContainer}>
      <Typography
        className={styles.assignmentName}
        variant="H-TEXT-1"
        color={colors.blue1}
      >
        {bulkUpload?.assignment?.name}
      </Typography>
      {_.isNil(focusedImage) ? (
        <Skeleton variant="rect" height={800} />
      ) : (
        <div className={styles.imageContainer}>
          <img
            src={focusedImage.image}
            alt={focusedImage.task_name}
            style={{
              transform: `rotate(${rotationCount * -90}deg)`
            }}
          />
          <div className={styles.imageButtonsContainer}>
            <ButtonGroup className={styles.imageButtonGroup}>
              <Button
                className={styles.pageNavigateButton}
                disabled={focusedPageNumber === 1}
                onClick={() =>
                  setFocusedPageNumber((prevCount) => prevCount - 1)
                }
              >
                &lt;
              </Button>

              <Button>
                Page {focusedPageNumber} / {totalPageNumbers}
              </Button>

              <Button
                className={styles.pageNavigateButton}
                disabled={focusedPageNumber === totalPageNumbers}
                onClick={() =>
                  setFocusedPageNumber((prevCount) => prevCount + 1)
                }
              >
                &gt;
              </Button>
              <Button
                className={styles.rotateButton}
                noTypography
                onClick={() => handleRotationClick()}
              >
                <SvgIcon
                  component={Rotate90DegreesCcwIcon}
                  className={styles.rotateIcon}
                />
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmPages;
