export const TrackerObjectiveValueTypes = {
  CHECKBOX: 'checkbox',
  CHECK_GRADE: 'check_grade',
  RUBRIC_ZERO_FOUR: 'rubric_zero_four',
  NUMBER: 'number',
  TEXT: 'text',
  RUBRIC_A_TO_F: 'rubric_a_to_f',
  CUSTOM: 'custom'
};

export const AssignmentStatusOptions = {
  ASSIGNED: 'assigned',
  REVISE: 'revise',
  SUBMITTED: 'submitted',
  COMPLETE: 'complete'
};

export const AssignmentTaskValueTypes = {
  CHECKBOX: 'checkbox',
  CHECK_GRADE: 'check_grade',
  RUBRIC_ZERO_FOUR: 'rubric_zero_four',
  NUMBER: 'number',
  TEXT: 'text',
  RUBRIC_A_TO_F: 'rubric_a_to_f',
  CUSTOM: 'custom'
};

export const AssignmentTaskValueTypeValueRange = {
  [AssignmentTaskValueTypes.RUBRIC_ZERO_FOUR]: [0, 1, 2, 3, 4],
  [AssignmentTaskValueTypes.RUBRIC_A_TO_F]: ['A', 'B', 'C', 'D', 'F']
};

export const AssignmentDepthOptions = {
  TASK: 1,
  CRITERIA: 2
};

export const MuiAutocompleteChangeReasons = {
  // For reference: https://material-ui.com/api/autocomplete/
  CREATE_OPTION: 'create-option',
  SELECT_OPTION: 'select-option',
  REMOVE_OPTION: 'remove-option',
  BLUR: 'blur',
  CLEAR: 'clear'
};

export const Environments = {
  DEV: 'development',
  STAGING: 'staging',
  PROD: 'production',
  REVIEW: 'review'
};

export const ImgixDimensions = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const SUBMITTED_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION = {
  key: 'all_submitted',
  value: 'submitted',
  label: 'Submitted'
};

export const INCOMPLETE_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION = {
  key: 'not_complete',
  value: 'incomplete',
  label: 'Incomplete'
};

export const ALL_STATUS_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION = {
  key: 'all',
  value: 'all_statuses',
  label: 'All Statuses'
};

export const STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTIONS = [
  SUBMITTED_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION,
  INCOMPLETE_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION,
  ALL_STATUS_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION
];

export const STUDENT_PORTFOLIO_STANDARD_TABS = {
  MATH: 'MATH',
  ELA: 'ELA',
  NO_STANDARDS: 'NO_STANDARDS'
};

export const STANDARD_MSA = {
  MAJOR: 'm',
  SUPPORTING: 's',
  ADDITIONAL: 'a'
};

export const MSA_LABEL_MAPPING = {
  m: 'Major',
  s: 'Supporting',
  a: 'Additional'
};
