import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Link } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { LOGIN_URL } from 'config/urls';
import {
  EDLIGHT_MAIN_DOMAIN_URL,
  STUDENT_SIGN_UP_URL,
  TEACHER_SIGN_UP_URL
} from 'config/urls';
import logo from 'images/logo-dark.svg';
import _ from 'lodash';

import AnonymousRequired from 'pages/Login/components/AnonymousRequired';
import { Typography } from 'pages/Walkthrough/components';
import LayoutWithBackgroundImage from 'pages/Walkthrough/components/LayoutWithBackgroundImage';
import { colors } from 'theme/palette';
import { useBreakpoints } from 'utils/resizing';
import { parseParams, stringifyParams } from 'utils/urls';

import Button from 'components/Button';
import Image from 'components/Image';

import Students from './assets/students.png';
import Teacher from './assets/teacher.png';
import styles from './styles.module.scss';

const SignUp = () => {
  const [rolesEnabled, setRolesEnabled] = useState(false);
  const [signInError, setSingInError] = useState(null);

  const history = useHistory();

  const searchParams = parseParams(history.location.search);
  const error = _.get(searchParams, 'error');

  useEffect(() => {
    if (_.isNil(signInError) && !_.isNil(error)) {
      setSingInError(error);

      delete searchParams['error'];

      history.replace({
        search: stringifyParams(searchParams)
      });
    }
  }, [error, history, searchParams, signInError]);

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  return (
    <LayoutWithBackgroundImage>
      <div className={styles.page}>
        {!isMobile && (
          <a href={EDLIGHT_MAIN_DOMAIN_URL}>
            <Image src={logo} className={styles.logoSmall} alt="Logo link" />
          </a>
        )}

        <div className={styles.container}>
          <Paper elevation={0} className={styles.paper}>
            <div>
              <div className={styles.content}>
                <Image
                  src={logo}
                  className={styles.logoBig}
                  alt="Logo placeholder"
                />
                {!_.isEmpty(signInError) && (
                  <Typography
                    variant="B-TEXT-2"
                    color={colors.pink2}
                    className={styles.signUpError}
                  >
                    {signInError}
                  </Typography>
                )}
                <Typography
                  variant="H-TEXT-1"
                  align="center"
                  color={colors.blue3}
                  className={styles.title}
                >
                  Sign up for EdLight
                </Typography>
                <Typography
                  variant="H-Text-3"
                  align="center"
                  color={colors.blue2}
                  className={styles.subtitle}
                >
                  Already Have An Account? <Link href={LOGIN_URL}>Sign In</Link>
                </Typography>

                <div className={styles.team}>
                  <div className={styles.role}>
                    <Image src={Students} className={styles.roleImage} />
                    <Button
                      color="blue"
                      className={styles.roleButton}
                      disabled={!rolesEnabled}
                      onClick={() => history.push(STUDENT_SIGN_UP_URL)}
                    >
                      I'm student
                    </Button>
                  </div>

                  <div className={styles.role}>
                    <Image src={Teacher} className={styles.roleImage} />
                    <Button
                      color="blue"
                      className={styles.roleButton}
                      disabled={!rolesEnabled}
                      onClick={() => {
                        history.push({
                          pathname: TEACHER_SIGN_UP_URL,
                          state: { hasAcceptedTermsAndConditions: true }
                        });
                      }}
                    >
                      I'm an educator
                    </Button>
                  </div>
                </div>

                <div className={styles.privacyAndTerms}>
                  <Checkbox
                    color="primary"
                    onClick={() => setRolesEnabled((current) => !current)}
                  />
                  <Typography
                    variant="B-TEXT-2"
                    align="center"
                    color={colors.grey3}
                    className={styles.terms}
                  >
                    By signing up, you agree to our{' '}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={'https://www.edlight.com/terms-of-service'}
                    >
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={'https://www.edlight.com/privacy-policy'}
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </LayoutWithBackgroundImage>
  );
};

export default AnonymousRequired(SignUp);
