import { withSentryRouting } from '@sentry/react';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import {
  ACTIVATION_BLOCKER_URL,
  FAMILY_URL,
  FREE_TRIAL_BLOCKER_URL,
  LOGIN_URL,
  MAGIC_LOGIN_URL,
  MOBILE_APP_URL,
  PATHWAYS_URL,
  ROSTERING_ERROR_URL,
  ROSTERING_LOADER_URL,
  SCHOOL_LOGIN_URL,
  SIGN_UP_URL,
  STUDENTS_URL,
  STUDENT_SIGN_UP_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL,
  TEACHERS_URL,
  TRY_URL,
  WALKTHROUGH_OVERVIEW_URL,
  WALKTHROUGH_URL,
  WEBCAM_PLAYGROUND_URL
} from 'config/urls';
import { createBrowserHistory } from 'history';
import {
  ActivationBlocker,
  Family,
  FreeTrialBlocker,
  Login,
  MagicLogin,
  MobileApp,
  Pathways,
  RosteringError,
  RosteringLoader,
  SchoolLogin,
  SignUp,
  StripeTest,
  StudentSignUp,
  Students,
  Teachers,
  Walkthrough,
  WebcamPlayground
} from 'pages';

import { PreviousLocationProvider } from 'pages/Teachers/shared/utils';
import { useIsMobile } from 'utils/resizing';

export const history = createBrowserHistory();

const SentryRoute = withSentryRouting(Route);

const TeachersAssignmentsListRedirect = ({ location }) => {
  // Since the <Redirect> component of React Router DOM *does not* receive any history props,
  // we need to wrap in in the `render` prop of the parent `Route` so that we can pass-through
  // query params that were initially passed.
  // Reference: https://stackoverflow.com/questions/51869083/how-preserve-query-string-and-hash-fragment-in-react-router-4-switchredirect
  const { search } = location;

  return (
    <Redirect
      to={{
        search,
        pathname: TEACHERS_ASSIGNMENTS_LIST_URL
      }}
    />
  );
};

const Routes = () => {
  const isMobile = useIsMobile();

  return (
    <Router history={history}>
      <PreviousLocationProvider>
        <Switch>
          <SentryRoute path={MOBILE_APP_URL} component={MobileApp} />
          <SentryRoute path={TEACHERS_URL} component={Teachers} />
          <SentryRoute path={PATHWAYS_URL} component={Pathways} />
          <SentryRoute path={STUDENTS_URL} component={Students} />
          <SentryRoute path={FAMILY_URL} component={Family} />
          <SentryRoute
            path={LOGIN_URL}
            render={(props) => <Login {...props} isMobile={isMobile} />}
          />

          <SentryRoute
            path={FREE_TRIAL_BLOCKER_URL}
            component={FreeTrialBlocker}
          />
          <SentryRoute
            path={ACTIVATION_BLOCKER_URL}
            component={ActivationBlocker}
          />
          <SentryRoute path={ROSTERING_ERROR_URL} component={RosteringError} />
          <SentryRoute
            path={ROSTERING_LOADER_URL}
            component={RosteringLoader}
          />
          <Redirect exact from={WALKTHROUGH_OVERVIEW_URL} to={TRY_URL} />
          <Redirect exact from={WALKTHROUGH_URL} to={TRY_URL} />
          <SentryRoute exact path={TRY_URL} component={Walkthrough} />
          <SentryRoute path={WALKTHROUGH_URL} component={Walkthrough} />
          <SentryRoute
            path={WEBCAM_PLAYGROUND_URL}
            component={WebcamPlayground}
          />
          <SentryRoute path={MAGIC_LOGIN_URL} component={MagicLogin} />
          <SentryRoute path={SCHOOL_LOGIN_URL} component={SchoolLogin} />
          <Route exact path={SIGN_UP_URL} component={SignUp} />
          <Route path={STUDENT_SIGN_UP_URL} component={StudentSignUp} />
          <Route path="/stripe" component={StripeTest} />
          <Route path="*" render={TeachersAssignmentsListRedirect} />
        </Switch>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
      </PreviousLocationProvider>
    </Router>
  );
};

export default Routes;
