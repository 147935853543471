import { BASE_URL } from 'config/urls';

import { get, requestSdk } from 'utils/sdk';

const URL = `${BASE_URL}/v1/auth/me/`;

export const me = async () => {
  return await requestSdk(() => get(URL));
};

export const fetchUsage = async () => {
  return await requestSdk(() => get(`${BASE_URL}/v1/subscriptions/usage/`));
};

export const fetchFreeTrialData = async () => {
  return await requestSdk(() => get(`${BASE_URL}/v1/users/free-trial-data/`));
};
