import React, { useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';
import { useYears } from 'sdk';

import AssignmentSelectionDisplay from './AssignmentSelectionDisplay';
import RightDrawer from './AssignmentSelectionDrawer';
import AssignmentSelectorContext from './AssignmentSelectorContext';

const AssignmentSelector = ({ selectedSchool, sectionId, onChange }) => {
  const { data: years, isLoading: isLoadingYears } = useYears();

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const yearsOptions = useMemo(() => {
    const yearsWithInsightsData = years?.filter((year) => year.id > 2023);

    return yearsWithInsightsData?.map((year) => {
      const startYear = new Date(year.naive_start_date).getFullYear();
      const endYear = new Date(year.naive_end_date).getFullYear();
      const label = `${startYear} - ${endYear}`;

      return {
        label,
        value: year.id
      };
    });
  }, [years]);

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  useEffect(() => {
    if (!isEmpty(yearsOptions) && !selectedYear) {
      setSelectedYear(yearsOptions[0]);
    }
  }, [yearsOptions, selectedYear, setSelectedYear]);

  // Workaround: Open and close the drawer on initial load to populate the values
  // since they are only fetched once the dropdowns in the drawer do their api calls
  useEffect(() => {
    if (!drawerOpen && !selectedSection && selectedSchool && !isLoadingYears) {
      setDrawerOpen(true); // Open the drawer to trigger the request
    }
  }, [drawerOpen, selectedSection, selectedSchool, isLoadingYears]);

  useEffect(() => {
    if (selectedSection && selectedLesson && !drawerVisible) {
      setDrawerOpen(false);
      const timer = setTimeout(() => {
        setDrawerVisible(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [selectedSection, selectedLesson, drawerVisible]);

  useEffect(
    () =>
      onChange({
        selectedSection,
        selectedGrade,
        selectedUnit,
        selectedLesson
      }),
    [onChange, selectedSection, selectedGrade, selectedUnit, selectedLesson]
  );

  if (isLoadingYears) {
    return <></>;
  }

  return (
    <AssignmentSelectorContext.Provider
      value={{
        selectedSection,
        selectedSchool,
        selectedYear,
        selectedGrade,
        selectedUnit,
        selectedLesson,
        setSelectedSection,
        setSelectedGrade,
        setSelectedUnit,
        setSelectedLesson
      }}
    >
      <AssignmentSelectionDisplay onClick={openDrawer} />
      <RightDrawer
        open={drawerOpen}
        onClose={closeDrawer}
        drawerVisible={drawerVisible}
        preSelectedSectionId={sectionId}
      />
    </AssignmentSelectorContext.Provider>
  );
};
export default AssignmentSelector;
