import React, { useCallback, useContext, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _ from 'lodash';
import { copyToClipboard } from 'utils';

import { ReactComponent as DownloadIcon } from 'pages/Teachers/Assignments/Results/assets/download-icon.svg';
import { ReactComponent as MultiSelectIcon } from 'pages/Teachers/Assignments/Results/assets/multiselect-icon.svg';
import { ReactComponent as ShareIcon } from 'pages/Teachers/Assignments/Results/assets/share-icon.svg';
import { colors } from 'theme/palette';
import { formatDate } from 'utils/moment';

import Button from 'components/Button';
import StandardItem from 'components/StandardItem';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { MultiSelectTooltip, ShareToButton, ShareTooltip } from './components';
import styles from './styles.module.scss';
import { getVisibleStandards } from './utils';

const ORGANIC_SIGN_UP_ENABLED =
  process.env.REACT_APP_ORGANIC_SIGN_UP_ENABLED === 'true';

const useStyles = makeStyles({
  label: {
    '@media (max-width: 992px)': { width: 24, height: 24 }
  }
});

const AssignmentSummary = ({
  assignment,
  filters,
  isAccordionOpen,
  selectedStudentWork,
  goToAssignmentDetail,
  exportResults,
  toggleAccordion,
  multiSelectTooltipOpen,
  shareTooltipOpen,
  tutoringTooltipOpen,
  onMultiSelectTooltipClick,
  onShareTooltipClick,
  addCommentBulk,
  addStickersBulk,
  changeTasksStatusesBulk,
  changeTasksScoresBulk,
  studentDeeplink,
  openAssignStudentGroupDialog,
  toggleSelectAllVisibleStudentWork,
  selectedStudentWorkList,
  openShareToMeetingDialog
}) => {
  const classes = useStyles();

  const user = useContext(UserContext);

  const isMultiSelectMode =
    multiSelectTooltipOpen || shareTooltipOpen || tutoringTooltipOpen;

  const copyStudentDeeplink = useCallback(() => {
    copyToClipboard(studentDeeplink, 'Student Link Copied to Clipboard.');
  }, [studentDeeplink]);

  const selectedResponsesValueTypes = useMemo(() => {
    const ids = _.map(selectedStudentWork, 'tracker_instance_objective_id');
    return _.map(
      _.filter(assignment.objectives, (x) => _.includes(ids, x.id)),
      'value_type'
    );
  }, [selectedStudentWork, assignment.objectives]);

  const responsesValueType = useMemo(
    () => _.head(selectedResponsesValueTypes),
    [selectedResponsesValueTypes]
  );
  const scoreChangeDisabled = useMemo(
    () => _.uniq(selectedResponsesValueTypes).length > 1,
    [selectedResponsesValueTypes]
  );

  const statusChangeDisabled = useMemo(
    () => _.some(_.map(selectedStudentWork, 'emptyWork')),
    [selectedStudentWork]
  );

  const selectedStudents = useMemo(
    () => _.map(selectedStudentWorkList, 'student'),
    [selectedStudentWorkList]
  );

  const shareToMeetingDialogData = useMemo(() => {
    const allSubmittedResponses = _(selectedStudentWorkList)
      .map('work')
      .flatten()
      .filter((w) => !_.get(w, 'emptyWork', false))
      .map('tracker_score_response_id')
      .value();
    return {
      students: selectedStudents,
      trackerScoreResponseIds: allSubmittedResponses
    };
  }, [selectedStudents, selectedStudentWorkList]);

  const openShareToMeetingDialogCallback = useCallback(() => {
    openShareToMeetingDialog(shareToMeetingDialogData);
  }, [openShareToMeetingDialog, shareToMeetingDialogData]);

  const visibleStandards = useMemo(
    () =>
      getVisibleStandards({
        assignment,
        visibleTaskIds: _.map(filters.tasks, 'id')
      }),
    [filters.tasks, assignment]
  );

  return (
    <>
      <MultiSelectTooltip
        open={multiSelectTooltipOpen}
        handleClose={onMultiSelectTooltipClick}
        addCommentBulk={addCommentBulk}
        addStickersBulk={addStickersBulk}
        changeTasksStatusesBulk={changeTasksStatusesBulk}
        toggleSelectAllVisibleStudentWork={toggleSelectAllVisibleStudentWork}
        changeTasksScoresBulk={changeTasksScoresBulk}
        scoreChangeDisabled={scoreChangeDisabled}
        responsesValueType={responsesValueType}
        statusChangeDisabled={statusChangeDisabled}
      >
        <div className={styles.summaryWrapper}>
          <div className={styles.titleWrapper}>
            <Typography variant="B-Text-3" color={colors.grey1}>
              Due: {formatDate(assignment.due_at, 'MM/DD hh:mm A')}
            </Typography>
            <Typography variant="B-Text-3" color={colors.grey1}>
              {assignment.complete_percentage}% complete (
              {assignment.complete_count})
            </Typography>
          </div>
          <div className={styles.buttonsWrapper}>
            <div className={styles.blueButtonsWrapper}>
              <Button
                color="blue"
                onClick={toggleAccordion}
                disabled={isMultiSelectMode}
                className={classnames({
                  [styles.dataButtonIsActive]: isAccordionOpen
                })}
              >
                Data
              </Button>
              {((user.user.is_paid && ORGANIC_SIGN_UP_ENABLED) ||
                !ORGANIC_SIGN_UP_ENABLED) && (
                <ShareToButton
                  disabled={isMultiSelectMode}
                  onShareTooltipClick={onShareTooltipClick}
                />
              )}
              <Button
                color="blue"
                onClick={goToAssignmentDetail}
                disabled={isMultiSelectMode}
              >
                Manage
              </Button>
            </div>

            <div className={styles.iconButtonsWrapper}>
              <Tooltip
                arrow
                title={
                  <Typography variant="B-Text-4">Select Multiple</Typography>
                }
              >
                <IconButton
                  classes={{ label: classes.label }}
                  disableRipple
                  size="small"
                  onClick={onMultiSelectTooltipClick}
                >
                  <MultiSelectIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                arrow
                title={
                  <Typography variant="B-Text-4">Copy Student Link</Typography>
                }
              >
                <IconButton
                  classes={{ label: classes.label }}
                  disableRipple
                  size="small"
                  onClick={copyStudentDeeplink}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>

              {_.isEmpty(assignment.csv_url) ? (
                <IconButton
                  classes={{ label: classes.label }}
                  disabled
                  onClick={exportResults}
                  disableRipple
                  size="small"
                >
                  <DownloadIcon />
                </IconButton>
              ) : (
                <Tooltip
                  arrow
                  title={
                    <Typography variant="B-Text-4">Download Data</Typography>
                  }
                >
                  <IconButton
                    classes={{ label: classes.label }}
                    onClick={exportResults}
                    disableRipple
                    size="small"
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </MultiSelectTooltip>

      {!_.isEmpty(visibleStandards) && (
        <Grid className={styles.standardsList} container spacing={1}>
          {visibleStandards.map((standard) => (
            <Grid item key={standard.id}>
              <StandardItem standard={standard} />
            </Grid>
          ))}
        </Grid>
      )}

      {shareTooltipOpen && (
        <ShareTooltip
          open={shareTooltipOpen}
          onShareTooltipClick={onShareTooltipClick}
          toggleSelectAllVisibleStudentWork={toggleSelectAllVisibleStudentWork}
          canAssign={!_.isEmpty(selectedStudentWork)}
          openShareToMeetingDialog={openShareToMeetingDialogCallback}
        >
          <div />
        </ShareTooltip>
      )}
    </>
  );
};

export default AssignmentSummary;
