import React, { useContext, useMemo } from 'react';

import { Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import WarningRounded from '@material-ui/icons/WarningRounded';
import { isNil } from 'lodash';
import { useBulkUploadsCountCreatedByUser } from 'sdk';

import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const TOTAL_TRIAL_DAYS = 30;

const FreeTrialReminder = ({ displayProgress }) => {
  const { user } = useContext(UserContext);

  const { data: bulkUploadsCount } = useBulkUploadsCountCreatedByUser();

  const email = 'example@example.com';
  const subject = 'Loving EdLight—Looking to Bring It to Our School';
  const body = useMemo(() => {
    if (user && !isNil(bulkUploadsCount)) {
      const minutes = bulkUploadsCount * 2;

      return `Hi,\n\nI’ve been using EdLight: https://www.edlight.com/ for the past two weeks, and it’s been a game-changer. EdLight is an AI math co-teacher that makes analyzing handwritten math work way easier and helps me identify which students need more support and how to provide it.\n\n\nSo far, with ${bulkUploadsCount} assignments in EdLight, I’ve saved ${minutes} minutes on feedback and analysis—giving me more time to focus on instruction.\n\nThey offer a 2-month pilot for 3 teachers for $500 if we want to try it out (https://www.edlight.com/). You can also take a product tour: https://edlight.navattic.com/wq1207s4 or check out their full pricing options: https://www.edlight.com/pricing-edlight if we’re ready to explore a school-wide partnership.\n\nI’ve attached an invoice for the pilot in case that helps move things forward. Would this be possible? I’d love to keep using EdLight and think it could benefit more than just me at our school.\n\nThank you for considering!\n\n ${user.name}`;
    }
  }, [user, bulkUploadsCount]);

  const mailto = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  const freeTrialRemainingDays = useMemo(() => {
    const nowTimestamp = Date.now();
    if (user && user.freeTrialData) {
      const startDateUTC = new Date(user.freeTrialData.free_trial_start_date);
      const startDateTimestamp = startDateUTC.getTime();

      const timeDifference = nowTimestamp - startDateTimestamp;

      const passedDays = Math.floor(timeDifference / (1000 * 3600 * 24));
      const remainingDays = TOTAL_TRIAL_DAYS - passedDays;

      if (remainingDays < 0) {
        return 0;
      }

      return Math.floor(remainingDays);
    }
  }, [user]);

  const freeTrialPercentagePassed = useMemo(() => {
    const nowTimestamp = Date.now(0);

    if (user && user.freeTrialData) {
      const startDateUTC = new Date(user.freeTrialData.free_trial_start_date);
      const startDateTimestamp = startDateUTC.getTime();

      const timeDifference = nowTimestamp - startDateTimestamp;

      const passedDays = Math.floor(timeDifference / (1000 * 3600 * 24));
      const percentagePassed = (passedDays / TOTAL_TRIAL_DAYS) * 100;

      return Math.min(Math.max(percentagePassed, 0), 100);
    }
  }, [user]);

  const onUpgradeClick = () => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'trial',
        view: 'default',
        button: 'upgrade'
      }
    );
  };

  return (
    <Box className={styles.freeTrialReminder}>
      <WarningRounded htmlColor={colors.pink1} />
      {displayProgress && (
        <LinearProgress
          className={styles.progress}
          variant="determinate"
          value={freeTrialPercentagePassed}
        />
      )}
      <Typography variant="B-Text-2">
        {freeTrialRemainingDays === 0 && (
          <span style={{ color: colors.white }}>Trial will end soon.</span>
        )}
        {freeTrialRemainingDays > 0 && (
          <>
            <span style={{ color: colors.white }}>
              {freeTrialRemainingDays} days
            </span>
            <span style={{ color: colors.blue10 }}> left in free trial.</span>
          </>
        )}
      </Typography>
      <Link
        href={mailto}
        onClick={onUpgradeClick}
        className={styles.upgradeLink}
      >
        Upgrade to pilot
      </Link>
    </Box>
  );
};

export default FreeTrialReminder;
