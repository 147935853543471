import { useCallback, useEffect, useReducer } from 'react';

import axios from 'axios';
import { history } from 'config/routes';
import {
  BASE_URL,
  LOGIN_URL,
  SIGN_UP_URL,
  TEACHERS_PERMISSION_DENIED_URL
} from 'config/urls';
import _ from 'lodash';
import moment from 'moment';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { identity } from 'utils';

import { notifyError } from 'utils/notifications';

const NEW_SIGN_UP_ENABLED =
  process.env.REACT_APP_NEW_SIGN_UP_ENABLED === 'true';

export const getConfig = () => {
  let config = {
    'Content-Type': 'application/json',
    withCredentials: true
  };

  return config;
};

export const handleSdk401 = (error) => {
  if (error.response && error.response.status === 401) {
    if (!NEW_SIGN_UP_ENABLED) {
      history.push(LOGIN_URL);
    } else {
      if (!window.location.href.includes(SIGN_UP_URL)) {
        history.push(SIGN_UP_URL);
      }
    }
  }

  throw error;
};

const handleSdk403 = (error) => {
  if (error.response && error.response.status === 403) {
    history.push(TEACHERS_PERMISSION_DENIED_URL);
  }
  throw error;
};

export const handleSdk404 = (error) => {
  if (error.response && error.response.status === 404) {
    notifyError('Not found.');
  }

  throw error;
};

export const handleSdk500 = (error) => {
  if (error.response && error.response.status === 500) {
    notifyError('Something went wrong.');
  }

  throw error;
};

export const get = (url, params = {}) => {
  return axios
    .get(url, { ...getConfig(), params })
    .catch(handleSdk401)
    .catch(handleSdk403);
};

export const post = (url, data, params) => {
  return axios
    .post(url, data, { ...getConfig(), ...params })
    .catch(handleSdk401)
    .catch(handleSdk403);
};

export const getBlob = (imageUrl) => {
  if ('fetch' in window) {
    return fetch(imageUrl)
      .then((resp) => resp.ok && resp.blob())
      .then((blob) => ({ data: blob }))
      .catch(notifyError);
  }

  return axios.get(imageUrl, { responseType: 'blob' });
};

export const requestSdk = async (sdk) => {
  let result = { data: {}, errors: [], success: false };

  try {
    const response = await sdk();

    result.data = response.data;
    result.success = true;
  } catch (error) {
    if (!error.response) {
      result.errors = [{ message: error.toString(), status: null }];
      return result;
    }

    if (error.response.status === 404) {
      result.errors = [{ message: 'Not found', status: error.response.status }];
    } else {
      result.errors = _.get(error, 'response.data.errors', ['Unknown error.']);
    }
  }

  return result;
};

export function formatDateForApi(date) {
  return moment(date).format('YYYY-MM-DD');
}

export const callUrl = (method, url, params) => {
  return new Promise((resolve) => {
    method(url, params)
      .then((response) => resolve(response.data))
      .catch(notifyError);
  });
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, data: action.data, loading: false };
    case 'FETCH_FAILURE':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const useFetch = (
  sdk,
  initialState,
  requestData,
  mapResponse = identity
) => {
  const [data, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    let didCancel = false;

    dispatch({ type: 'FETCH' });
    sdk(requestData)
      .then((response) => {
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', data: mapResponse(response) });
        }
      })
      .catch(() => {
        if (!didCancel) {
          // this won't fire because of a bad implementation of the sdk sometimes
          dispatch({ type: 'FETCH_FAILURE' });
        }
      });

    return () => {
      didCancel = true;
    };
  }, [requestData, sdk, mapResponse]);

  return data;
};

export const useFetchV2 = (url, params = {}, errorHandlers = {}) => {
  // Gives you the ability to define your own handlers.
  const defaultErrorHandlers = {
    401: handleSdk401,
    403: handleSdk403,
    404: handleSdk404,
    500: handleSdk500,
    ...errorHandlers
  };

  const fetcher = ([url]) =>
    axios
      .get(`${BASE_URL}${url}`, { ...getConfig(), params })
      .catch((error) => {
        defaultErrorHandlers[error.response.status](error);
      })
      .then((res) => res.data);

  const { data, error, isValidating, isLoading, mutate } = useSWR(
    url,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0
    }
  );

  const refetch = useCallback(() => {
    mutate();
  }, [mutate]);

  return {
    data,
    error,
    isValidating,
    mutate,
    refetch,
    isLoading: isLoading || data === undefined
  };
};

export const useFetchInfinite = (url, params = {}) => {
  const fetcher = (url) =>
    axios
      .get(`${BASE_URL}${url}`, { ...getConfig(), params })
      .catch(handleSdk401)
      .catch(handleSdk403)
      .catch(handleSdk404)
      .catch(handleSdk500)
      .then((res) => res.data);

  const { data, error, isValidating, mutate, size, setSize } = useSWRInfinite(
    url,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      revalidateFirstPage: false
    }
  );

  const refetch = useCallback(() => {
    mutate();
  }, [mutate]);

  return {
    size,
    setSize,
    data,
    error,
    isValidating,
    mutate,
    refetch,
    isLoading: (!error && data === undefined) || isValidating
  };
};
