import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import MuiButton from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { GOOGLE_CLASSROOM_LOGIN_REDIRECT_URI } from 'config/constants';
import {
  EDLIGHT_MAIN_DOMAIN_URL,
  FAMILY_PLANNER_URL,
  LOGIN_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  WALKTHROUGH_ROLLOUT_URL
} from 'config/urls';
import { TEACHERS_PERMISSION_DENIED_URL } from 'config/urls';
import logo from 'images/logo-dark.svg';
import { get, join } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useMe } from 'sdk';

import { logout } from 'pages/Teachers/shared/TeacherPageLayout/components/TopBar/sdk';
import { Typography } from 'pages/Walkthrough/components';
import LayoutWithBackgroundImage from 'pages/Walkthrough/components/LayoutWithBackgroundImage';
import { colors } from 'theme/palette';
import { openGoogleLoginPage } from 'utils/integrations/google';
import { bootIntercomWidget } from 'utils/integrations/intercom';
import { shutdownIntercomWidget } from 'utils/integrations/intercom';
import { default as pusherManager } from 'utils/integrations/pusher';
import { useBreakpoints } from 'utils/resizing';
import { parseParams, stringifyParams } from 'utils/urls';

import Button from 'components/Button';
import Image from 'components/Image';

import { ReactComponent as GoogleClassroomIcon } from './assets/google_icon.svg';
import ErrorIcon from './assets/mdi_error.svg';
import { ReactComponent as ManualUploadIcon } from './assets/template.svg';
import styles from './styles.module.scss';

const RosteringError = () => {
  const history = useHistory();

  const searchParams = parseParams(history.location.search);

  const { data: user, isLoading } = useMe();

  const schoolId = get(searchParams, 'schoolId');
  const clever = get(searchParams, 'clever');
  const manual = get(searchParams, 'manual');

  useEffect(() => {
    if (!schoolId && !manual) {
      history.push(TEACHERS_PERMISSION_DENIED_URL);
    }
  }, [schoolId, history, manual]);

  useEffect(() => {
    bootIntercomWidget();
    return () => shutdownIntercomWidget();
  }, []);

  useEffect(() => {
    if (!isLoading && !user) {
      history.push(LOGIN_URL);
    }
    if (!user) {
      return;
    }
    if (!user.is_teacher && user.student_id) {
      history.push(STUDENTS_ASSIGNMENTS_LIST_URL);
    }
    if (!user.is_teacher && user.is_family_member) {
      history.push(FAMILY_PLANNER_URL);
    }
  }, [user, isLoading, history]);

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  const logoutAndRedirect = async () => {
    shutdownIntercomWidget();
    pusherManager.disconnect();
    await logout().then(() => {
      history.push(LOGIN_URL);

      if (process.env.REACT_APP_MIXPANEL_KEY) {
        // Disassociate events from the user
        mixpanel.reset();
      }
    });
  };

  const loadViaGoogleClassroom = () => {
    const redirectUri = GOOGLE_CLASSROOM_LOGIN_REDIRECT_URI;
    const scope = join(
      [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/classroom.profile.emails',
        'https://www.googleapis.com/auth/classroom.courses.readonly',
        'https://www.googleapis.com/auth/classroom.rosters.readonly'
      ],
      ' '
    );
    const state = {
      schoolId,
      userId: user.id,
      searchParams
    };
    openGoogleLoginPage(redirectUri, scope, state);
  };

  return (
    <LayoutWithBackgroundImage>
      <div className={styles.page}>
        {!isMobile && (
          <a href={EDLIGHT_MAIN_DOMAIN_URL}>
            <Image src={logo} className={styles.logoSmall} alt="Logo link" />
          </a>
        )}

        <MuiButton className={styles.logInButton} onClick={logoutAndRedirect}>
          <Typography variant="H-TEXT-2" color={colors.blue2}>
            Log Out
          </Typography>
        </MuiButton>

        <div className={styles.container}>
          <Paper elevation={0} className={styles.paper}>
            <div>
              <div className={styles.content}>
                <Image
                  src={logo}
                  className={styles.logoBig}
                  alt="Logo placeholder"
                />
                <Image src={ErrorIcon} alt="Error icon" />
                <Typography
                  variant="H-TEXT-1"
                  color={colors.pink1}
                  className={styles.title}
                >
                  Uh oh!
                </Typography>
                <Typography
                  variant="H-Text-1"
                  color={colors.blue4}
                  className={styles.description}
                >
                  Something went wrong setting up your class.
                </Typography>
                <div className={styles.grayLine} />
                {!clever && (
                  <>
                    <div className={styles.roster}>
                      <Typography
                        variant="B-TEXT-2"
                        color={colors.blue3}
                        className={styles.rosterText}
                      >
                        Try again:
                      </Typography>
                      <Button
                        className={styles.rosterButton}
                        startIcon={<GoogleClassroomIcon />}
                        variant="textAndIcon"
                        onClick={loadViaGoogleClassroom}
                        noTypography
                      >
                        <Typography variant="B-Text-2" color={colors.white}>
                          Import from google Classroom
                        </Typography>
                      </Button>
                    </div>

                    <div className={styles.grayLine} />
                    <div className={styles.roster}>
                      <Typography
                        variant="B-TEXT-2"
                        color={colors.blue3}
                        className={styles.rosterText}
                      >
                        Try manual upload:
                      </Typography>
                      <Button
                        className={styles.rosterButton}
                        startIcon={<ManualUploadIcon />}
                        variant="textAndIcon"
                        onClick={() =>
                          history.push({
                            pathname: WALKTHROUGH_ROLLOUT_URL,
                            search: stringifyParams({ activeStep: 2, schoolId })
                          })
                        }
                        noTypography
                      >
                        <Typography variant="B-Text-2" color={colors.white}>
                          Upload Manually
                        </Typography>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </LayoutWithBackgroundImage>
  );
};

export default RosteringError;
