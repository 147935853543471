import React from 'react';

import {
  Card,
  CardContent,
  CircularProgress,
  Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const StudentWorkCardSkeleton = () => {
  return (
    <Card style={{ maxWidth: 345 }}>
      <div
        style={{
          height: 140,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </div>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          <Skeleton width="90%" />
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <Skeleton width="85%" />
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <Skeleton width="60%" />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StudentWorkCardSkeleton;
