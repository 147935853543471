import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import { Link } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  ACTIVATION_BLOCKER_URL,
  EDLIGHT_MAIN_DOMAIN_URL,
  FAMILY_PLANNER_URL,
  LOGIN_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL
} from 'config/urls';
import logo from 'images/logo-dark.svg';
import { isNil } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useBulkUploadsCountCreatedByUser, useMe } from 'sdk';

import { logout } from 'pages/Teachers/shared/TeacherPageLayout/components/TopBar/sdk';
import { Typography } from 'pages/Walkthrough/components';
import LayoutWithBackgroundImage from 'pages/Walkthrough/components/LayoutWithBackgroundImage';
import { colors } from 'theme/palette';
import { bootIntercomWidget } from 'utils/integrations/intercom';
import { shutdownIntercomWidget } from 'utils/integrations/intercom';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { default as pusherManager } from 'utils/integrations/pusher';
import { useBreakpoints } from 'utils/resizing';

import Image from 'components/Image';

import { ReactComponent as SupportIcon } from './assets/Calendar.svg';
import { ReactComponent as MailIcon } from './assets/mail.svg';
import { ReactComponent as ReferIcon } from './assets/remind.svg';
import styles from './styles.module.scss';

const FreeTrialBlocker = () => {
  const history = useHistory();
  const { data: user, isLoading } = useMe();
  const { data: bulkUploadsCount } = useBulkUploadsCountCreatedByUser();

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  const logoutAndRedirect = async () => {
    shutdownIntercomWidget();
    pusherManager.disconnect();
    await logout().then(() => {
      history.push(LOGIN_URL);

      if (process.env.REACT_APP_MIXPANEL_KEY) {
        // Disassociate events from the user
        mixpanel.reset();
      }
    });
  };

  const email = 'example@example.com';
  const subject = 'Loving EdLight—Looking to Bring It to Our School';
  const body = useMemo(() => {
    if (user && !isNil(bulkUploadsCount)) {
      const minutes = bulkUploadsCount * 2;

      return `Hi,\n\nI’ve been using EdLight: https://www.edlight.com/ for the past two weeks, and it’s been a game-changer. EdLight is an AI math co-teacher that makes analyzing handwritten math work way easier and helps me identify which students need more support and how to provide it.\n\n\nSo far, with ${bulkUploadsCount} assignments in EdLight, I’ve saved ${minutes} minutes on feedback and analysis—giving me more time to focus on instruction.\n\nThey offer a 2-month pilot for 3 teachers for $500 if we want to try it out (https://www.edlight.com/). You can also take a product tour: https://edlight.navattic.com/wq1207s4 or check out their full pricing options: https://www.edlight.com/pricing-edlight if we’re ready to explore a school-wide partnership.\n\nI’ve attached an invoice for the pilot in case that helps move things forward. Would this be possible? I’d love to keep using EdLight and think it could benefit more than just me at our school.\n\nThank you for considering!\n\n ${user.name}`;
    }
  }, [user, bulkUploadsCount]);

  const mailto = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  useEffect(() => {
    if (user) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.PAGE} ${MixpanelAction.VIEW}`,
        {
          feature: 'trial',
          view: 'blocker'
        }
      );
    }
  }, [user]);

  useEffect(() => {
    bootIntercomWidget();
    return () => shutdownIntercomWidget();
  }, []);

  useEffect(() => {
    if (!isLoading && !user) {
      history.push(LOGIN_URL);
    }
    if (!user) {
      return;
    }
    if (!user.is_teacher && user.student_id) {
      history.push(STUDENTS_ASSIGNMENTS_LIST_URL);
    }
    if (!user.is_teacher && user.is_family_member) {
      history.push(FAMILY_PLANNER_URL);
    }
    if (
      user.is_teacher &&
      (user.is_in_free_trial || user.is_paid) &&
      user.onboarding_stage === 'activated'
    ) {
      history.push(TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL);
    }
    if (user.onboarding_stage === 'waiting_for_meetings') {
      history.push(ACTIVATION_BLOCKER_URL);
    }
  }, [user, isLoading, history]);

  const onOptionClick = ({ buttonType }) => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'trial',
        view: 'blocker',
        button: buttonType
      }
    );
  };

  return (
    <LayoutWithBackgroundImage>
      <div className={styles.page}>
        {!isMobile && (
          <a href={EDLIGHT_MAIN_DOMAIN_URL}>
            <Image src={logo} className={styles.logoSmall} alt="Logo link" />
          </a>
        )}

        <MuiButton className={styles.logInButton} onClick={logoutAndRedirect}>
          <Typography variant="H-TEXT-2" color={colors.blue2}>
            Log Out
          </Typography>
        </MuiButton>

        <div className={styles.container}>
          <Paper elevation={0} className={styles.paper}>
            <div>
              <div className={styles.content}>
                <Image
                  src={logo}
                  className={styles.logoBig}
                  alt="Logo placeholder"
                />

                <Typography
                  variant="S-TEXT-1"
                  align="center"
                  color={colors.grey3}
                  className={styles.welcomeText}
                >
                  Welcome
                </Typography>

                <Typography
                  variant="H-TEXT-2"
                  align="center"
                  color={colors.pink}
                >
                  Your free trial has ended
                </Typography>

                <Typography
                  variant="B-TEXT-2"
                  align="center"
                  color={colors.blue1}
                  className={styles.thankYouText}
                >
                  👋 Thanks for trying EdLight! To keep accessing your AI-math
                  co-teacher, choose one of these options:
                </Typography>

                <Link
                  onClick={() => onOptionClick({ buttonType: 'upgrade' })}
                  href={mailto}
                  className={styles.emailButton}
                >
                  <SvgIcon component={MailIcon} />
                  <div className={styles.emailText}>
                    Contact your school leader to Upgrade
                  </div>
                </Link>

                <Link
                  onClick={() => onOptionClick({ buttonType: 'refer' })}
                  href={'https://tally.so/r/m6Wz2P'}
                  className={styles.referButton}
                >
                  <SvgIcon component={ReferIcon} />
                  <div className={styles.referText}>
                    Refer 3 Teachers get 30 more days
                  </div>
                </Link>

                <Link
                  className={styles.supportButton}
                  href={'https://calendly.com/d/cp5m-4cr-cyy/edlight-demo'}
                  onClick={() => onOptionClick({ buttonType: 'contact' })}
                >
                  <SvgIcon component={SupportIcon} />
                  <div className={styles.supportText}>Contact support</div>
                </Link>

                <Typography
                  variant="B-TEXT-4"
                  align="center"
                  color={colors.grey4}
                  className={styles.trialText}
                >
                  * Trial refreshes once 3 of your referrals create an EdLight
                  account!
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </LayoutWithBackgroundImage>
  );
};

export default FreeTrialBlocker;
