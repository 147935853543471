import React, { useContext, useEffect } from 'react';

import { isEmpty, isNil } from 'lodash';

import EmptyDataState from 'pages/Teachers/InsightsV2/components/EmptyDataState';
import LoadingSkeleton from 'pages/Teachers/InsightsV2/components/LoadingSkeleton';
import MasteryLevelGroup from 'pages/Teachers/InsightsV2/components/MasteryLevelGroup';
import ProgressBar from 'pages/Teachers/InsightsV2/components/ProgressBar';
import {
  CELEBRATE,
  CELEBRATE_COLOR,
  GENERAL_CONCEPTUAL_UNDERSTANDING,
  INTERVENE,
  INTERVENE_COLOR,
  OVERALL_RESPONSE_CELEBRATE_COLLAPSIBLE,
  OVERALL_RESPONSE_INTERVENE_COLLAPSIBLE,
  OVERALL_RESPONSE_SUPPORT_COLLAPSIBLE,
  PENDING,
  PENDING_COLOR,
  SIGNIFICANT_ERRORS_IN_WORK,
  SUPPORT,
  SUPPORT_COLOR,
  UNABLE_TO_DIAGNOSE,
  WORK_IS_COMPLETE_AND_CORRECT
} from 'pages/Teachers/InsightsV2/constants';
import {
  trackMixpanelEvent,
  trackMixpanelPageView
} from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as CelebrateIcon } from './assets/celebrate-icon.svg';
import { ReactComponent as InterveneIcon } from './assets/intervene-icon.svg';
import { ReactComponent as PendingIcon } from './assets/pending-icon.svg';
import { ReactComponent as SupportIcon } from './assets/support-icon.svg';
import styles from './styles.module.scss';

const OverallResponseOverview = ({ data, isLoading, refetchOverview }) => {
  const user = useContext(UserContext);

  useEffect(() => {
    trackMixpanelPageView(user.id, {
      feature: 'insights',
      view: 'overall response'
    });
  }, [user]);

  const trackOverallResponseMasteryLevelGroupExpand = ({ groupName }) => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'insights',
        view: 'overall response',
        button: groupName
      }
    );
  };

  const trackOverallResponseStudentWorkDialogClick = ({ groupName }) => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'insights',
        view: 'overall response',
        button: 'student work',
        group: groupName
      }
    );
  };

  const trackOverallResponseNextStepsMarkAsCompleted = ({
    completedNextStep
  }) => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.FORM} ${MixpanelAction.SUBMIT}`,
      {
        feature: 'insights',
        view: 'overall response',
        button: 'complete',
        next_step: completedNextStep
      }
    );
  };

  if (isLoading && isNil(data)) return <LoadingSkeleton />;

  const {
    celebrate_data: {
      student_percentage: celebratePercentage,
      students: celebrateStudents,
      next_steps: celebrateNextSteps,
      exemplar_urls: celebrateExemplarUrls
    },
    support_data: {
      student_percentage: supportPercentage,
      students: supportStudents,
      next_steps: supportNextSteps,
      exemplar_urls: supportExemplarUrls
    },
    intervene_data: {
      student_percentage: intervenePercentage,
      students: interveneStudents,
      next_steps: interveneNextSteps,
      exemplar_urls: interveneExemplarUrls
    },
    no_strategy_data: {
      student_percentage: noStrategyPercentage,
      students: noStrategyStudents,
      next_steps: noStrategyNextSteps,
      exemplar_urls: noStrategyExemplarUrls
    }
  } = data;

  const celebratePercentageParsed = parseFloat(celebratePercentage);
  const supportPercentageParsed = parseFloat(supportPercentage);
  const intervenePercentageParsed = parseFloat(intervenePercentage);
  const noStrategyPercentageParsed = parseFloat(noStrategyPercentage);

  const hasNoData =
    celebratePercentageParsed === 0 &&
    supportPercentageParsed === 0 &&
    intervenePercentageParsed === 0 &&
    noStrategyPercentageParsed === 0;

  if (!isLoading && hasNoData) {
    return <EmptyDataState />;
  }

  const unsubmittedPercentage = Math.max(
    0,
    1 -
      (celebratePercentageParsed +
        supportPercentageParsed +
        intervenePercentageParsed +
        noStrategyPercentageParsed)
  );

  return (
    <div className={styles.container}>
      <Typography variant="H-TEXT-2" style={{ textTransform: 'none' }}>
        To help you get started, we&apos;ve grouped your students by
        mastery—those excelling (Celebrate), those progressing (Support), and
        those needing targeted support (Intervention). Our AI helps get you
        started, but tweak groups as needed to celebrate progress and reteach
        effectively with our intervention strategies.
      </Typography>
      <ProgressBar
        celebratePercentage={celebratePercentage}
        supportPercentage={supportPercentage}
        intervenePercentage={intervenePercentage}
        unsubmittedPercentage={unsubmittedPercentage}
        noStrategyPercentage={noStrategyPercentage}
      />
      <div data-intercom-target={OVERALL_RESPONSE_CELEBRATE_COLLAPSIBLE}>
        <MasteryLevelGroup
          title={CELEBRATE}
          icon={<CelebrateIcon />}
          students={celebrateStudents}
          percentage={celebratePercentage}
          nextSteps={celebrateNextSteps}
          exemplarUrls={celebrateExemplarUrls}
          color={CELEBRATE_COLOR}
          description={WORK_IS_COMPLETE_AND_CORRECT}
          refetchOverview={refetchOverview}
          trackGroupExpandEvent={() =>
            trackOverallResponseMasteryLevelGroupExpand({
              groupName: CELEBRATE
            })
          }
          trackStudentWorkDialogClickEvent={() =>
            trackOverallResponseStudentWorkDialogClick({ groupName: CELEBRATE })
          }
          trackNextStepCompleteEvent={
            trackOverallResponseNextStepsMarkAsCompleted
          }
        />
      </div>
      <div data-intercom-target={OVERALL_RESPONSE_SUPPORT_COLLAPSIBLE}>
        <MasteryLevelGroup
          title={SUPPORT}
          icon={<SupportIcon />}
          students={supportStudents}
          percentage={supportPercentage}
          nextSteps={supportNextSteps}
          exemplarUrls={supportExemplarUrls}
          color={SUPPORT_COLOR}
          description={GENERAL_CONCEPTUAL_UNDERSTANDING}
          refetchOverview={refetchOverview}
          trackGroupExpandEvent={() =>
            trackOverallResponseMasteryLevelGroupExpand({ groupName: SUPPORT })
          }
          trackStudentWorkDialogClickEvent={() =>
            trackOverallResponseStudentWorkDialogClick({ groupName: SUPPORT })
          }
          trackNextStepCompleteEvent={
            trackOverallResponseNextStepsMarkAsCompleted
          }
        />
      </div>
      <div data-intercom-target={OVERALL_RESPONSE_INTERVENE_COLLAPSIBLE}>
        <MasteryLevelGroup
          title={INTERVENE}
          icon={<InterveneIcon />}
          students={interveneStudents}
          percentage={intervenePercentage}
          nextSteps={interveneNextSteps}
          exemplarUrls={interveneExemplarUrls}
          color={INTERVENE_COLOR}
          description={SIGNIFICANT_ERRORS_IN_WORK}
          refetchOverview={refetchOverview}
          trackGroupExpandEvent={() =>
            trackOverallResponseMasteryLevelGroupExpand({
              groupName: INTERVENE
            })
          }
          trackStudentWorkDialogClickEvent={() =>
            trackOverallResponseStudentWorkDialogClick({ groupName: INTERVENE })
          }
          trackNextStepCompleteEvent={
            trackOverallResponseNextStepsMarkAsCompleted
          }
        />
      </div>
      {!isEmpty(noStrategyStudents) && (
        <div>
          <MasteryLevelGroup
            title={PENDING}
            icon={<PendingIcon />}
            students={noStrategyStudents}
            percentage={noStrategyPercentage}
            nextSteps={noStrategyNextSteps}
            exemplarUrls={noStrategyExemplarUrls}
            color={PENDING_COLOR}
            description={UNABLE_TO_DIAGNOSE}
            refetchOverview={refetchOverview}
            trackGroupExpandEvent={() =>
              trackOverallResponseMasteryLevelGroupExpand({
                groupName: PENDING
              })
            }
            trackStudentWorkDialogClickEvent={() =>
              trackOverallResponseStudentWorkDialogClick({
                groupName: PENDING
              })
            }
            trackNextStepCompleteEvent={
              trackOverallResponseNextStepsMarkAsCompleted
            }
          />
        </div>
      )}
    </div>
  );
};

export default OverallResponseOverview;
