import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';
import { Redirect } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { TEACHERS_STUDENT_PORTFOLIO_URL } from 'config/urls';
import { TEACHERS_PERMISSION_DENIED_URL } from 'config/urls';

import { useNavbar } from 'pages/Teachers/shared/TeacherPageLayout';
import { colors } from 'theme/palette';
import { useMixpanelPageOpenTrack } from 'utils/integrations/mixpanel';
import { reverse } from 'utils/urls';

import DebouncedField from 'components/DebouncedField';
import SearchFilter from 'components/SearchFilter';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext.js';

import { fetchStudentsList } from './sdk.js';
import styles from './styles.module.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, data: action.data, loading: false };
    case 'FETCH_FAILURE':
      return { ...state, loading: false };
    default:
      return state;
  }
};

// TODO: once this is abstract enough - we can throw it in the utils
const useFetch = (sdk, initialState, requestData) => {
  const [data, dispatch] = useReducer(reducer, initialState);
  useNavbar({ title: 'Students' });

  useEffect(() => {
    let didCancel = false;

    dispatch({ type: 'FETCH' });
    sdk(requestData)
      .then((response) => {
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', data: response });
        }
      })
      .catch(() => {
        if (!didCancel) {
          // this won't fire because of a bad implementation of the sdk sometimes
          dispatch({ type: 'FETCH_FAILURE' });
        }
      });

    return () => {
      didCancel = true;
    };
  }, [requestData, sdk]);

  return data;
};

// The only reason we have this component is to deal with the DebouncedField onChange API
const Search = ({ onChange, ...props }) => (
  <SearchFilter
    onChange={(e, student) => {
      onChange({ target: { value: student } }); // Cope with the DebouncedField API
    }}
    {...props}
  />
);

const StudentsList = ({ history }) => {
  const { user } = useContext(UserContext);

  const [value, setValue] = useState('');

  const search = useMemo(() => ({ search: value }), [value]);

  const studentsList = useFetch(fetchStudentsList, { data: [] }, search);

  const options = useMemo(
    () =>
      studentsList.data.map((student) => ({
        id: student.id,
        label: student.name,
        grade: `${student.grade_ordinal} Grade` || '-'
      })),
    [studentsList]
  );

  useMixpanelPageOpenTrack('Teacher opened Students page');

  if (user.is_in_free_trial) {
    return <Redirect to={TEACHERS_PERMISSION_DENIED_URL} />;
  }

  return (
    <Paper className={styles.paper}>
      <Typography variant="H-TEXT-3" color={colors.blue1}>
        Student portfolios
      </Typography>
      <Typography
        variant="B-Text-2"
        color={colors.grey1}
        className={styles.subheader}
      >
        View results by student
      </Typography>

      <div className={styles.searchContainer}>
        <DebouncedField
          component={Search}
          options={options}
          placeholder="Student Name"
          onInputChange={(e, text) => {
            setValue(text);
          }}
          onChange={(student) => {
            history.push(
              reverse(TEACHERS_STUDENT_PORTFOLIO_URL, { id: student.id })
            );
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(option) => (
            <div className={styles.option}>
              <Typography variant="B-Text-3" color={colors.grey2}>
                {option.label}
              </Typography>
              <Typography variant="B-Text-3" color={colors.grey2}>
                {option.grade}
              </Typography>
            </div>
          )}
        />
        {studentsList.loading && (
          <LinearProgress
            className={styles.searchBarLoading}
            classes={{
              colorPrimary: styles.searchBarLoadingColor,
              bar: styles.searchBarBarLoadingColor
            }}
          />
        )}
      </div>
    </Paper>
  );
};

export default StudentsList;
