import React from 'react';

import { ReactComponent as AIGeneratorIcon } from 'pages/Teachers/Assignments/Results/assets/ai.svg';
import { colors } from 'theme/palette';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const GradeLightConfirmationDialog = ({ onButtonClick, onClose }) => (
  <Dialog
    open
    alignTop
    fullWidth
    onClose={onClose}
    classes={{ paper: styles.dialog }}
  >
    <div className={styles.container}>
      <Typography align="center" variant="H-Text-3" color={colors.blue3}>
        Don’t want to start from scratch? Try our AI-powered grader, GradeLight,
        to provide relevant feedback to your students. You get final say before
        anything is shared with students.
      </Typography>

      <div className={styles.buttonsContainer}>
        <Button
          color="pink"
          onClick={onButtonClick}
          startIcon={<AIGeneratorIcon />}
        >
          Start Ai Grading
        </Button>
      </div>
    </div>
  </Dialog>
);

export default GradeLightConfirmationDialog;
