import React, { useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

import { colors } from 'theme/palette';
import {
  AssignmentTaskValueTypeValueRange,
  AssignmentTaskValueTypes
} from 'utils/constants';

import InputField from 'components/InputField';
import Typography from 'components/Typography';

import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as CheckMinusIcon } from './assets/check_minus.svg';
import { ReactComponent as CheckPlusIcon } from './assets/check_plus.svg';
import styles from './styles.module.scss';

const CheckBoxOptions = ({ changeScore }) => (
  <MenuList className={styles.optionsList}>
    <MenuItem
      className={styles.scoreCheckGradeItem}
      onClick={() => {
        changeScore(1);
      }}
    >
      <SvgIcon
        component={CheckPlusIcon}
        fontSize="small"
        htmlColor={colors.blue3}
      />
      <Typography noWrap variant="B-Text-3" color={colors.blue3}>
        Correct
      </Typography>
    </MenuItem>

    <MenuItem
      className={styles.scoreCheckGradeItem}
      onClick={() => {
        changeScore(0);
      }}
    >
      <SvgIcon
        component={CheckMinusIcon}
        fontSize="small"
        htmlColor={colors.blue3}
      />
      <Typography noWrap variant="B-Text-3" color={colors.blue3}>
        Incorrect
      </Typography>
    </MenuItem>
  </MenuList>
);

const CheckGradeOptions = ({ changeScore }) => (
  <MenuList className={styles.optionsList}>
    <MenuItem
      className={styles.scoreCheckGradeItem}
      onClick={() => {
        changeScore(1);
      }}
    >
      <SvgIcon
        component={CheckPlusIcon}
        fontSize="small"
        htmlColor={colors.blue3}
      />
      <Typography noWrap variant="B-Text-3" color={colors.blue3}>
        Check Plus
      </Typography>
    </MenuItem>

    <MenuItem
      className={styles.scoreCheckGradeItem}
      onClick={() => {
        changeScore(0);
      }}
    >
      <SvgIcon
        component={CheckIcon}
        fontSize="small"
        htmlColor={colors.blue3}
      />
      <Typography noWrap variant="B-Text-3" color={colors.blue3}>
        Check
      </Typography>
    </MenuItem>

    <MenuItem
      className={styles.scoreCheckGradeItem}
      onClick={() => {
        changeScore(-1);
      }}
    >
      <SvgIcon
        component={CheckMinusIcon}
        fontSize="small"
        htmlColor={colors.blue3}
      />
      <Typography noWrap variant="B-Text-3" color={colors.blue3}>
        Check Minus
      </Typography>
    </MenuItem>
  </MenuList>
);

const RubricOptions = ({ options, changeScore }) => (
  <MenuList className={styles.rubricOptionsList}>
    {_.map(options, (option) => (
      <MenuItem
        key={option}
        onClick={() => changeScore(option)}
        className={styles.rubricOption}
      >
        <Typography
          noWrap
          variant="H-TEXT-3"
          color={colors.grey3}
          className={styles.scoreTextNumber}
        >
          {option}
        </Typography>
      </MenuItem>
    ))}
  </MenuList>
);

const NumberInput = ({ changeScore }) => {
  const [input, setInput] = useState('');

  return (
    <InputField
      type="number"
      variant="underlined"
      className={styles.numberInput}
      value={input}
      placeholder="1-100"
      onChange={(e) => setInput(e.target.value)}
      onKeyDown={(e) => {
        // Submit on enter
        if (e.keyCode === 13) {
          changeScore(input);
        }
      }}
    />
  );
};

const ChangeScoreDropdown = ({ changeScore, valueType }) => {
  if (valueType === AssignmentTaskValueTypes.CHECK_GRADE) {
    return <CheckGradeOptions changeScore={changeScore} />;
  }
  if (valueType === AssignmentTaskValueTypes.CHECKBOX) {
    return <CheckBoxOptions changeScore={changeScore} />;
  }
  if (valueType === AssignmentTaskValueTypes.RUBRIC_ZERO_FOUR) {
    return (
      <RubricOptions
        options={AssignmentTaskValueTypeValueRange[valueType]}
        changeScore={changeScore}
      />
    );
  }
  if (valueType === AssignmentTaskValueTypes.RUBRIC_A_TO_F) {
    return (
      <RubricOptions
        options={AssignmentTaskValueTypeValueRange[valueType]}
        changeScore={changeScore}
      />
    );
  }
  if (valueType === AssignmentTaskValueTypes.NUMBER) {
    return <NumberInput changeScore={changeScore} />;
  }
  if (valueType === AssignmentTaskValueTypes.CUSTOM) {
    return <NumberInput changeScore={changeScore} />;
  }
  return null;
};

const valueTypes = [
  AssignmentTaskValueTypes.CHECKBOX,
  AssignmentTaskValueTypes.CHECK_GRADE,
  AssignmentTaskValueTypes.RUBRIC_ZERO_FOUR,
  AssignmentTaskValueTypes.RUBRIC_A_TO_F,
  AssignmentTaskValueTypes.NUMBER,
  AssignmentTaskValueTypes.CUSTOM
];

export const ChangeScoreTooltip = ({
  open,
  changeScore,
  valueType,
  children
}) => (
  <Tooltip
    open={open && _.indexOf(valueTypes, valueType) !== -1}
    arrow
    placement="bottom-start"
    disableFocusListener
    disableHoverListener
    disableTouchListener
    classes={{
      popper: styles.scorePopper,
      tooltip: styles.scoreBox,
      arrow: styles.arrow
    }}
    title={
      <ChangeScoreDropdown changeScore={changeScore} valueType={valueType} />
    }
  >
    {children}
  </Tooltip>
);
