import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  TEACHER_SIGN_UP_URL,
  TRY_URL,
  WALKTHROUGH_CLEVER_LIBRARY_ROLLOUT_URL,
  WALKTHROUGH_CREATE_PROFILE_URL,
  WALKTHROUGH_ROLLOUT_URL,
  WALKTHROUGH_SIGN_UP_WITH_PASSWORD
} from 'config/urls';

import SignUpWithPassword from 'pages/Walkthrough/components/SignUpWithPassword';
import {
  bootIntercomWidget,
  shutdownIntercomWidget,
  updateIntercomEntity
} from 'utils/integrations/intercom';

import {
  CleverLibraryRolloutForm,
  CreateProfileForm,
  LayoutWithBackgroundImage,
  RolloutForm,
  TeacherSignUp,
  TeacherStudentChosePage,
  WalkthroughContext
} from './components';

const Walkthrough = ({ location }) => {
  const [walkthroughUser, setWalkthroughUser] = useState(null);

  useEffect(() => {
    bootIntercomWidget();
    return () => shutdownIntercomWidget();
  }, []);

  useEffect(() => {
    updateIntercomEntity();
  }, [location.pathname]);

  return (
    <WalkthroughContext.Provider
      value={{ walkthroughUser, setWalkthroughUser }}
    >
      <LayoutWithBackgroundImage>
        <Switch>
          {/* Standard Walkthrough */}
          <Route exact path={TRY_URL} component={TeacherStudentChosePage} />
          <Route exact path={TEACHER_SIGN_UP_URL} component={TeacherSignUp} />
          <Route
            exact
            path={WALKTHROUGH_CREATE_PROFILE_URL}
            component={CreateProfileForm}
          />
          <Route
            exact
            path={WALKTHROUGH_SIGN_UP_WITH_PASSWORD}
            component={SignUpWithPassword}
          />

          <Route exact path={WALKTHROUGH_ROLLOUT_URL} component={RolloutForm} />

          {/* Clever Library Teachers Walkthrough */}
          <Route
            exact
            path={WALKTHROUGH_CLEVER_LIBRARY_ROLLOUT_URL}
            component={CleverLibraryRolloutForm}
          />

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </LayoutWithBackgroundImage>
    </WalkthroughContext.Provider>
  );
};

export default Walkthrough;
