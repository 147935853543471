import React, { useEffect, useMemo, useState } from 'react';

import MuiButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import { GOOGLE_SIGN_UP_REDIRECT_URL } from 'config/constants';
import {
  EDLIGHT_MAIN_DOMAIN_URL,
  LOGIN_URL,
  WALKTHROUGH_SIGN_UP_WITH_PASSWORD
} from 'config/urls';
import { Field, Formik } from 'formik';
import logo from 'images/logo-dark.svg';
import _ from 'lodash';
import {
  useMeetingInvitationDetail,
  walkthroughUserAcceptTermsAndConditions
} from 'sdk';

import { Typography, useWalkthroughUser } from 'pages/Walkthrough/components';
import { colors } from 'theme/palette';
import { openGoogleLoginPage } from 'utils/integrations/google';
import { formatDate } from 'utils/moment';
import { useBreakpoints } from 'utils/resizing';
import { stringifyParams } from 'utils/urls';
import { parseParams } from 'utils/urls';

import Button from 'components/Button';
import Image from 'components/Image';

import { ReactComponent as GoogleIcon } from './assets/google.svg';
import { ReactComponent as GoogleDisabledIcon } from './assets/google_disabled.svg';
import styles from './styles.module.scss';

export const signUpWithGoogle = ({ history }) => {
  const state = parseParams(history.location.search);

  const redirectUri = GOOGLE_SIGN_UP_REDIRECT_URL;

  const scope = _.join(
    [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile'
    ],
    ' '
  );

  openGoogleLoginPage(redirectUri, scope, state);
};

const CreateProfileForm = ({ history }) => {
  useWalkthroughUser();

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [signInError, setSingInError] = useState(null);

  const searchParams = parseParams(history.location.search);

  const invitationIdentifier = _.get(searchParams, 'invitationIdentifier');
  const error = _.get(searchParams, 'error');

  useEffect(() => {
    if (_.isNil(signInError) && !_.isNil(error)) {
      setSingInError(error);

      delete searchParams['error'];

      history.replace({
        search: stringifyParams(searchParams)
      });
    }
  }, [error, history, searchParams, signInError]);

  const { data: meetingIvitationDetails } = useMeetingInvitationDetail({
    invitationIdentifier
  });

  const meetingTopic = useMemo(() => {
    if (_.isNil(meetingIvitationDetails)) {
      return;
    }

    const meetingTopic = meetingIvitationDetails.topic;
    const scheduledFor = meetingIvitationDetails.scheduled_for;
    const scheduleDateFormatted = formatDate(scheduledFor);

    if (_.isEmpty(meetingTopic)) {
      return `Meeting - ${scheduleDateFormatted}`;
    } else {
      return `${meetingTopic} - ${scheduleDateFormatted}`;
    }
  }, [meetingIvitationDetails]);

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  return (
    <div className={styles.page}>
      {!isMobile && (
        <a href={EDLIGHT_MAIN_DOMAIN_URL}>
          <Image src={logo} className={styles.logoSmall} alt="Logo link" />
        </a>
      )}
      {!isMobile && (
        <MuiButton
          className={styles.logInButton}
          onClick={() =>
            history.push({
              pathname: LOGIN_URL,
              search: history.location.search
            })
          }
        >
          <Typography variant="H-TEXT-2" color={colors.blue2}>
            Log In
          </Typography>
        </MuiButton>
      )}
      <div className={styles.container}>
        <Paper elevation={0} className={styles.paper}>
          <Formik>
            {() => {
              return (
                <div className={styles.formContainer}>
                  <Image
                    src={logo}
                    className={styles.logoBig}
                    alt="Logo placeholder"
                  />

                  {_.isNil(invitationIdentifier) ? (
                    <Typography
                      variant="H-TEXT-1"
                      align="center"
                      color={colors.grey3}
                    >
                      Create Account
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        variant="S-TEXT-1"
                        color={colors.grey2}
                        style={{ marginBottom: '10px' }}
                      >
                        You have been invited to join
                      </Typography>
                      <Typography variant="H-TEXT-3" color={colors.grey1}>
                        {meetingTopic}
                      </Typography>{' '}
                    </>
                  )}

                  {!_.isEmpty(signInError) && (
                    <Typography
                      variant="B-TEXT-3"
                      color={colors.pink2}
                      className={styles.signUpError}
                    >
                      {signInError}
                    </Typography>
                  )}

                  <div className={styles.checkboxContainer}>
                    <Field
                      type="checkbox"
                      component={FormControlLabel}
                      control={
                        <Checkbox
                          value={termsAndConditionsAccepted}
                          onChange={() =>
                            setTermsAndConditionsAccepted(
                              !termsAndConditionsAccepted
                            )
                          }
                          size="small"
                        />
                      }
                      className={styles.checkboxLabel}
                      label={
                        <Typography
                          variant="B-Text-2"
                          color={colors.grey1}
                          className={styles.checkboxLabelText}
                        >
                          I agree to the Edlight{' '}
                          <a
                            href="https://www.edlight.com/terms-of-service"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.link}
                            onClick={(e) => e.stopPropagation()}
                          >
                            Terms of service
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://www.edlight.com/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.link}
                            onClick={(e) => e.stopPropagation()}
                          >
                            Privacy Policy
                          </a>
                        </Typography>
                      }
                    />
                  </div>
                  <Button
                    fullWidth
                    noTypography
                    variant="contained"
                    classes={{
                      root: styles.googleButton,
                      label: styles.googleButtonLabel,
                      startIcon: styles.googleButtonIcon
                    }}
                    startIcon={
                      <SvgIcon
                        component={
                          !termsAndConditionsAccepted
                            ? GoogleDisabledIcon
                            : GoogleIcon
                        }
                        fontSize="large"
                      />
                    }
                    disabled={!termsAndConditionsAccepted}
                    onClick={async () => {
                      await walkthroughUserAcceptTermsAndConditions();
                      signUpWithGoogle({ history });
                    }}
                  >
                    <Typography
                      variant="B-Text-2"
                      color={
                        !termsAndConditionsAccepted
                          ? colors.grey4
                          : colors.white
                      }
                      align="center"
                      className={styles.googleSignUpText}
                    >
                      Sign Up with Google
                    </Typography>
                  </Button>
                  <Button
                    fullWidth
                    color="blue"
                    noTypography
                    onClick={async () => {
                      await walkthroughUserAcceptTermsAndConditions();
                      history.push({
                        pathname: WALKTHROUGH_SIGN_UP_WITH_PASSWORD,
                        search: history.location.search
                      });
                    }}
                    disabled={!termsAndConditionsAccepted}
                  >
                    <Typography variant="S-TEXT-1" color={colors.white}>
                      Sign up with email & password
                    </Typography>
                  </Button>
                </div>
              );
            }}
          </Formik>
        </Paper>
      </div>
    </div>
  );
};

export default CreateProfileForm;
