import React, { useState } from 'react';

import { isEmpty } from 'lodash';

import NextStepsTable from 'pages/Teachers/InsightsV2/components/NextStepsTable';
import StudentSummaryDialog from 'pages/Teachers/InsightsV2/components/StudentSummaryDialog';
import { STUDENT_WORK_INTERCOM_TARGET } from 'pages/Teachers/InsightsV2/constants';
import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const MasteryLevelDetail = ({
  misconceptionName,
  students,
  nextSteps,
  exemplarUrls,
  refetchOverview,
  trackStudentWorkDialogClickEvent,
  trackNextStepCompleteEvent
}) => {
  const [summaryDialogIsOpen, setSummaryDialogIsOpen] = useState(false);
  const [studentName, setStudentName] = useState('');

  const [workTagImages, setWorkTagImages] = useState([]);

  const handleStudentClickV2 = ({ name, workTagImageIds }) => {
    trackStudentWorkDialogClickEvent();

    setStudentName(name);
    setWorkTagImages(workTagImageIds);
    setSummaryDialogIsOpen(true);
  };

  const handleNextIconClick = () => {
    if (isEmpty(studentName)) return;

    const currentIndex = students.findIndex(
      (item) => item.name === studentName
    );
    const nextIndex = (currentIndex + 1) % students.length;

    const student = students[nextIndex];

    setWorkTagImages(student.work_tag_image_ids);

    setStudentName(student.name);
  };

  const handlePreviousIconClick = () => {
    if (isEmpty(studentName)) return;

    const currentIndex = students.findIndex(
      (item) => item.name === studentName
    );
    const previousIndex =
      (currentIndex - 1 + students.length) % students.length;

    const student = students[previousIndex];

    setWorkTagImages(student.work_tag_image_ids);

    setStudentName(student.name);
  };

  return (
    <div className={styles.details}>
      <div
        data-intercom-target={STUDENT_WORK_INTERCOM_TARGET}
        className={styles.students}
      >
        <div className={styles.studentsContainer}>
          {students.map(({ name, work_tag_image_ids }) => (
            <div
              key={name}
              className={styles.studentNameContainer}
              onClick={() => {
                handleStudentClickV2({
                  name: name,
                  workTagImageIds: work_tag_image_ids
                });
              }}
            >
              <Typography
                variant="B-TEXT-4"
                className={styles.studentName}
                color={colors.blue10}
              >
                {name}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      {!isEmpty(nextSteps) && (
        <div className={styles.nextSteps}>
          <NextStepsTable
            nextSteps={nextSteps}
            refetchOverview={refetchOverview}
            exemplarUrls={exemplarUrls}
            trackNextStepCompleteEvent={trackNextStepCompleteEvent}
          />
        </div>
      )}
      {summaryDialogIsOpen && (
        <StudentSummaryDialog
          misconceptionName={misconceptionName}
          studentName={studentName}
          workTagImageIds={workTagImages}
          allStudentsData={students}
          refetchOverview={refetchOverview}
          isSummaryDialogIsOpen={summaryDialogIsOpen}
          handleNextIconClick={handleNextIconClick}
          handlePreviousIconClick={handlePreviousIconClick}
          onClose={() => {
            setSummaryDialogIsOpen(false);
            setStudentName('');
          }}
        />
      )}
    </div>
  );
};

export default MasteryLevelDetail;
