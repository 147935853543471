import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from '@material-ui/core';
import { LOGIN_URL } from 'config/urls';
import mixpanel from 'mixpanel-browser';
import { useMe, userAcceptTermsAndConditions } from 'sdk';

import { logout } from 'pages/Teachers/shared/TeacherPageLayout/components/TopBar/sdk';
import { shutdownIntercomWidget } from 'utils/integrations/intercom';
import { default as pusherManager } from 'utils/integrations/pusher';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const TermsAndConditionsBlockerDialog = () => {
  const history = useHistory();
  const { data: user, refetch } = useMe();
  const [isOpen, setIsOpen] = useState(false);

  const handleAccept = async () => {
    await userAcceptTermsAndConditions();
    refetch();
  };

  const logoutAndRedirect = async () => {
    shutdownIntercomWidget();
    pusherManager.disconnect();
    await logout().then(() => {
      history.push(LOGIN_URL);

      if (process.env.REACT_APP_MIXPANEL_KEY) {
        // Disassociate events from the user
        mixpanel.reset();
      }
    });
  };

  useEffect(() => {
    if (!user) return;

    if (!user.has_accepted_terms_and_conditions) setIsOpen(true);
    if (user.has_accepted_terms_and_conditions) setIsOpen(false);
  }, [user]);

  return (
    <Dialog open={isOpen}>
      <div className={styles.container}>
        <Typography variant="B-TEXT-2" align="center">
          By continuing, you agree to our{' '}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={'https://www.edlight.com/terms-of-service'}
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={'https://www.edlight.com/privacy-policy'}
          >
            Privacy Policy
          </Link>
          . Please read them carefully before proceeding.
        </Typography>
        <Button color="pink" onClick={handleAccept}>
          Accept terms and conditions
        </Button>
        <Button color="gray" onClick={logoutAndRedirect}>
          Log Out
        </Button>
      </div>
    </Dialog>
  );
};

export default TermsAndConditionsBlockerDialog;
