import React from 'react';
import { withRouter } from 'react-router';

import {
  ACTIVATION_BLOCKER_URL,
  FAMILY_PLANNER_URL,
  FREE_TRIAL_BLOCKER_URL,
  LOGIN_URL,
  SIGN_UP_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL
} from 'config/urls';
import _ from 'lodash';

import { stringifyParams } from 'utils/urls';

import UserContext from 'components/UserContext';

import { fetchFreeTrialData, fetchUsage, me } from './sdk';

const NEW_SIGN_UP_ENABLED =
  process.env.REACT_APP_NEW_SIGN_UP_ENABLED === 'true';

const TeacherRequired = (WrappedComponent) => {
  class HOC extends React.Component {
    redirectToLogin = ({ fromMeetingFeed }) => {
      const {
        location: { pathname, search }
      } = this.props;

      let searchParams = stringifyParams({ next: pathname + search });

      if (fromMeetingFeed) {
        const pathnameList = pathname.split('/');

        searchParams = stringifyParams({
          next: pathname + search,
          invitationIdentifier: pathnameList[3]
        });
      }

      this.props.history &&
        this.props.history.push({
          pathname: NEW_SIGN_UP_ENABLED ? SIGN_UP_URL : LOGIN_URL,
          search: searchParams
        });
    };

    redirectToStudentApp = () =>
      this.props.history &&
      this.props.history.push(STUDENTS_ASSIGNMENTS_LIST_URL);

    redirectToFamilyApp = () =>
      this.props.history && this.props.history.push(FAMILY_PLANNER_URL);

    fetchMe = async () => {
      const promises = [me(), fetchUsage(), fetchFreeTrialData()];
      const resolved = await Promise.all(promises);

      const { data: user } = resolved[0];
      const usageResult = resolved[1];
      const freeTrialData = resolved[2];

      if (!_.isEmpty(user)) {
        user.usage = usageResult.data.usage;
        user.freeTrialData = freeTrialData.data;
      }

      return user;
    };

    ensureUser = ({ user, setUser, fromMeetingFeed = false }) => {
      if (!user) {
        this.fetchMe().then((me) => {
          !_.isEmpty(me)
            ? setUser(me)
            : this.redirectToLogin({ fromMeetingFeed });
        });
        return null;
      }

      if (
        process.env.REACT_APP_ORGANIC_SIGN_UP_ENABLED === 'true' &&
        user &&
        user.is_teacher &&
        !user.is_in_free_trial &&
        !user.is_paid &&
        user.onboarding_stage === 'activated'
      ) {
        this.props.history.push(FREE_TRIAL_BLOCKER_URL);
        return null;
      }

      if (
        process.env.REACT_APP_ORGANIC_SIGN_UP_ENABLED === 'true' &&
        user &&
        user.is_teacher &&
        !user.is_in_free_trial &&
        !user.is_paid &&
        user.onboarding_stage === 'waiting_for_meetings'
      ) {
        this.props.history.push(ACTIVATION_BLOCKER_URL);
        return null;
      }

      if (user && !user.is_teacher && user.student_id) {
        this.redirectToStudentApp();
        return null;
      }

      if (user && !user.is_teacher && user.is_family_member) {
        this.redirectToFamilyApp();
        return null;
      }

      return user;
    };

    render() {
      return (
        <UserContext.Consumer>
          {({ user, setUser }) => {
            const {
              location: { pathname }
            } = this.props;

            const pathnameList = pathname.split('/');

            // Check whether we are on the "/teacher/meetings/:invitationIdentifier" page
            const fromMeetingFeed =
              pathnameList.length === 4 &&
              pathnameList[1] === 'teacher' &&
              pathnameList[2] === 'meetings';

            const ensuredUser = fromMeetingFeed
              ? this.ensureUser({
                  user,
                  setUser,
                  fromMeetingFeed: true
                })
              : this.ensureUser({ user, setUser });

            if (ensuredUser) {
              return <WrappedComponent user={ensuredUser} {...this.props} />;
            }
          }}
        </UserContext.Consumer>
      );
    }
  }

  return withRouter(HOC);
};

export default TeacherRequired;
