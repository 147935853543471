import React from 'react';

import ProgressTab from 'pages/Teachers/InsightsV2/components/ProgressTab';
import {
  CELEBRATE,
  CELEBRATE_COLOR,
  GENERAL_CONCEPTUAL_UNDERSTANDING,
  INTERVENE,
  INTERVENE_COLOR,
  PENDING,
  PENDING_COLOR,
  SIGNIFICANT_ERRORS_IN_WORK,
  SUPPORT,
  SUPPORT_COLOR,
  UNABLE_TO_DIAGNOSE,
  UNSUBMITTED_COLOR,
  UNSUBMITTED_DESCRIPTION,
  UNSUBMITTED_LABEL,
  WORK_IS_COMPLETE_AND_CORRECT
} from 'pages/Teachers/InsightsV2/constants';

import styles from './styles.module.scss';

const ProgressBar = ({
  celebratePercentage,
  supportPercentage,
  intervenePercentage,
  unsubmittedPercentage,
  noStrategyPercentage
}) => {
  const celebratePercentageFixed = Math.round(celebratePercentage * 100);
  const supportPercentageFixed = Math.round(supportPercentage * 100);
  const intervenePercentageFixed = Math.round(intervenePercentage * 100);
  const unsubmittedPercentageFixed = Math.round(unsubmittedPercentage * 100);
  const noStrategyPercentageFixed = Math.round(noStrategyPercentage * 100);

  return (
    <div className={styles.progressBars}>
      {celebratePercentageFixed !== 0 && (
        <ProgressTab
          label={CELEBRATE}
          description={WORK_IS_COMPLETE_AND_CORRECT}
          percentage={celebratePercentageFixed}
          hoverPopupColor={CELEBRATE_COLOR}
        />
      )}
      {supportPercentageFixed !== 0 && (
        <ProgressTab
          label={SUPPORT}
          description={GENERAL_CONCEPTUAL_UNDERSTANDING}
          percentage={supportPercentageFixed}
          hoverPopupColor={SUPPORT_COLOR}
        />
      )}
      {intervenePercentageFixed !== 0 && (
        <ProgressTab
          label={INTERVENE}
          description={SIGNIFICANT_ERRORS_IN_WORK}
          percentage={intervenePercentageFixed}
          hoverPopupColor={INTERVENE_COLOR}
        />
      )}
      {noStrategyPercentageFixed !== 0 && (
        <ProgressTab
          label={PENDING}
          description={UNABLE_TO_DIAGNOSE}
          percentage={noStrategyPercentageFixed}
          hoverPopupColor={PENDING_COLOR}
        />
      )}
      {unsubmittedPercentageFixed !== 0 && (
        <ProgressTab
          label={UNSUBMITTED_LABEL}
          description={UNSUBMITTED_DESCRIPTION}
          percentage={unsubmittedPercentageFixed}
          hoverPopupColor={UNSUBMITTED_COLOR}
        />
      )}
    </div>
  );
};

export default ProgressBar;
