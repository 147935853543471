import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { Link } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  EDLIGHT_MAIN_DOMAIN_URL,
  FAMILY_PLANNER_URL,
  LOGIN_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL
} from 'config/urls';
import logo from 'images/logo-dark.svg';
import mixpanel from 'mixpanel-browser';
import { useMe } from 'sdk';

import { logout } from 'pages/Teachers/shared/TeacherPageLayout/components/TopBar/sdk';
import { Typography } from 'pages/Walkthrough/components';
import LayoutWithBackgroundImage from 'pages/Walkthrough/components/LayoutWithBackgroundImage';
import SignupStepper from 'pages/Walkthrough/components/SignupStepper';
import { colors } from 'theme/palette';
import { shutdownIntercomWidget } from 'utils/integrations/intercom';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { default as pusherManager } from 'utils/integrations/pusher';
import { useBreakpoints } from 'utils/resizing';

import Image from 'components/Image';

import FirstTeammate from './assets/first_teammate.png';
import { ReactComponent as MailIcon } from './assets/mail.svg';
import SecondTeammate from './assets/second_teammate.png';
import styles from './styles.module.scss';

const ActivationBlocker = () => {
  const history = useHistory();
  const { data: user, isLoading } = useMe();

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  const logoutAndRedirect = async () => {
    shutdownIntercomWidget();
    pusherManager.disconnect();
    await logout().then(() => {
      history.push(LOGIN_URL);

      if (process.env.REACT_APP_MIXPANEL_KEY) {
        // Disassociate events from the user
        mixpanel.reset();
      }
    });
  };

  useEffect(() => {
    if (!isLoading && !user) {
      history.push(LOGIN_URL);
    }
    if (!user) {
      return;
    }
    if (!user.is_teacher && user.student_id) {
      history.push(STUDENTS_ASSIGNMENTS_LIST_URL);
    }
    if (!user.is_teacher && user.is_family_member) {
      history.push(FAMILY_PLANNER_URL);
    }
    if (user.is_teacher && (user.is_in_free_trial || user.is_paid)) {
      history.push(TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL);
    }
  }, [user, isLoading, history]);

  const onButtonClick = () => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'sign up',
        view: 'blocker',
        button: 'schedule a meeting'
      }
    );
  };

  return (
    <LayoutWithBackgroundImage>
      <div className={styles.page}>
        {!isMobile && (
          <a href={EDLIGHT_MAIN_DOMAIN_URL}>
            <Image src={logo} className={styles.logoSmall} alt="Logo link" />
          </a>
        )}

        <MuiButton className={styles.logInButton} onClick={logoutAndRedirect}>
          <Typography variant="H-TEXT-2" color={colors.blue2}>
            Log Out
          </Typography>
        </MuiButton>

        <div className={styles.container}>
          <Paper elevation={0} className={styles.paper}>
            <div>
              <div className={styles.content}>
                <Image
                  src={logo}
                  className={styles.logoBig}
                  alt="Logo placeholder"
                />

                <SignupStepper currentStepIndex={2} />

                <Typography
                  variant="H-TEXT-1"
                  align="center"
                  color={colors.pink}
                  className={styles.header}
                >
                  Activate Your Account
                </Typography>
                <Typography
                  variant="H-Text-2"
                  align="center"
                  color={colors.blue2}
                  className={styles.subtitle}
                >
                  Schedule time to onboard with an EdLight Teammate
                </Typography>

                <div className={styles.team}>
                  <div className={styles.teamMate}>
                    <Typography
                      variant="B-Text-1"
                      color={colors.blue1}
                      className={styles.teamMateText}
                    >
                      “Hey, I'm Lindsay, former Middle School math teacher and
                      forever math nerd. Grab a time on my calendar!”
                    </Typography>
                    <Image
                      src={FirstTeammate}
                      className={styles.teamMateImage}
                    />
                  </div>

                  <div className={styles.teamMate}>
                    <Typography
                      variant="B-Text-1"
                      color={colors.blue1}
                      className={styles.teamMateText}
                    >
                      “Hello, I’m Rob, former Middle School Math Teacher and
                      every student’s favorite. Book time with me!”
                    </Typography>
                    <Image
                      src={SecondTeammate}
                      className={styles.teamMateImage}
                    />
                  </div>
                </div>

                <Link
                  className={styles.emailButton}
                  href={'https://calendly.com/d/cp5m-4cr-cyy/edlight-demo'}
                  onClick={onButtonClick}
                >
                  <SvgIcon component={MailIcon} />
                  <div className={styles.emailText}>schedule a meeting</div>
                </Link>

                <Typography
                  variant="B-Text-1"
                  align="center"
                  color={colors.blue1}
                  className={styles.teamMateText}
                >
                  to activate your account
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </LayoutWithBackgroundImage>
  );
};

export default ActivationBlocker;
