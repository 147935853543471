import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import {
  TEACHERS_PERMISSION_DENIED_URL,
  TEACHERS_SUBSCRIPTIONS_CHANGE_PLAN_URL,
  TEACHERS_SUBSCRIPTIONS_DASHBOARD_URL
} from 'config/urls';

import ChangePlan from './ChangePlan';
import Dashboard from './Dashboard';

const ORGANIC_SIGN_UP_ENABLED =
  process.env.REACT_APP_ORGANIC_SIGN_UP_ENABLED === 'true';

const TeacherSubscriptions = () => {
  const history = useHistory();

  useEffect(() => {
    if (ORGANIC_SIGN_UP_ENABLED) {
      history.push(TEACHERS_PERMISSION_DENIED_URL);
    }
  }, [history]);

  return (
    <Switch>
      <Route
        exact
        path={TEACHERS_SUBSCRIPTIONS_DASHBOARD_URL}
        component={Dashboard}
      />
      <Route
        exact
        path={TEACHERS_SUBSCRIPTIONS_CHANGE_PLAN_URL}
        component={ChangePlan}
      />

      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default TeacherSubscriptions;
