import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import classnames from 'classnames';
import _ from 'lodash';
import { copyToClipboard, dataTabular } from 'utils';

import {
  getPercentageLabel,
  getScoreResponsesHistogram
} from 'pages/Teachers/Assignments/Results/utils';
import { StackedProgressBar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { AssignmentTaskValueTypes } from 'utils/constants';
import { getObjectiveHistogram, getObjectiveValueLabel } from 'utils/trackers';

import Dialog from 'components/Dialog';
import Flex from 'components/Flex';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const getBarColors = (objective, histogram) => {
  const isParent = !objective.isChild;

  const COLOR_PALETTE = {
    task: {
      1: ['blue1'],
      2: ['blue1', 'blue4'],
      3: ['blue1', 'grey3', 'blue4'],
      4: ['blue1', 'grey4', 'blue4', 'grey2'],
      5: ['blue1', 'grey4', 'blue4', 'grey2', 'blue5']
    },
    criteria: ['grey1', 'grey5']
  };

  const values = _.map(histogram, '[1]');

  const colorPalette = isParent
    ? _.get(COLOR_PALETTE['task'], _.size(values), [])
    : COLOR_PALETTE['criteria'];

  const barColors = _.values(_.pick(colors, colorPalette));

  return barColors;
};

const StudentsDialog = ({
  data: { scoreResponses, objective, key },
  groupedStudents,
  groupedScoreResponses,
  onClose
}) => {
  const headers = ['Local student ID', 'First name', 'Last name'];

  const number = _.fromPairs(scoreResponses)[key];

  const valueLabel = getObjectiveValueLabel(objective, key);
  const percentageLabel = getPercentageLabel(scoreResponses, number);

  const students = _.chain(groupedScoreResponses[objective.id])
    .filter((response) => response.raw_value.toString() === key)
    .map((response) => groupedStudents[response.student_id])
    .value();

  return (
    <Dialog open alignTop fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle disableTypography>
        <Grid container justifyContent="space-between" direction="row">
          <Grid
            container
            item
            xs={11}
            spacing={3}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="H-TEXT-2" color={colors.blueDark}>
                {objective.name}
              </Typography>
            </Grid>
            <Grid item className={styles.modalLabelContainer}>
              <Tooltip
                title={
                  <Typography variant="B-Text-2" color={colors.white}>
                    {valueLabel}
                  </Typography>
                }
              >
                <div
                  className={classnames(styles.modalLabel, styles.labelWrapper)}
                >
                  <Typography
                    noWrap
                    variant="S-TEXT-2"
                    color={colors.white}
                    className={classnames(
                      {
                        [styles.modalCheckValueLabel]: _.includes(
                          [
                            AssignmentTaskValueTypes.CHECKBOX,
                            AssignmentTaskValueTypes.CHECK_GRADE
                          ],
                          objective.value_type
                        )
                      },
                      styles.modalValueLabel
                    )}
                  >
                    {valueLabel}
                  </Typography>
                  <Typography
                    variant="S-TEXT-2"
                    color={colors.white}
                    display="inline"
                    className={styles.percentageLabel}
                  >
                    {percentageLabel}
                  </Typography>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container item xs={1}>
            <IconButton
              onClick={() => copyToClipboard(dataTabular(students))}
              disableRipple
            >
              <FileCopyRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} variant="outlined">
          <Table stickyHeader>
            <TableHead>
              <TableRow className={styles.row}>
                {_.map(headers, (header) => (
                  <TableCell key={header}>
                    <Typography variant="S-TEXT-1" color={colors.blueDark}>
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(students, (student) => (
                <TableRow key={student.id} className={styles.row}>
                  <TableCell>
                    <Typography variant="B-Text-3" color={colors.grey1}>
                      {student.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="B-Text-3" color={colors.grey1}>
                      {student.first_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="B-Text-3" color={colors.grey1}>
                      {student.last_name}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

const ObjectivePercentageLabel = ({ objective, histogram, onClick }) => {
  const barColors = getBarColors(objective, histogram);
  return (
    <Grid container item xs spacing={2} alignItems="center">
      {_.map(histogram, (item, index) => {
        const [key, value] = item;

        const valueLabel = getObjectiveValueLabel(
          objective,
          key,
          barColors[index]
        );
        const percentageLabel = getPercentageLabel(histogram, value);

        return (
          <Grid
            item
            key={index}
            className={classnames(styles.labelContainer, 'pointer')}
            onClick={() => onClick(histogram, objective, key)}
          >
            <Tooltip
              title={
                <Typography variant="B-Text-2" color={colors.white}>
                  {valueLabel}
                </Typography>
              }
            >
              <div className={styles.labelWrapper}>
                <Typography
                  noWrap
                  variant="S-TEXT-2"
                  color={colors.blueDark}
                  className={styles.valueLabel}
                >
                  {valueLabel}
                </Typography>
                <Typography
                  variant="S-TEXT-2"
                  display="inline"
                  color={colors.blueDark}
                  className={styles.percentageLabel}
                >
                  {percentageLabel}
                </Typography>
              </div>
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
};

const ObjectiveProgressBar = ({ objective, histogram, onClick }) => {
  const isParent = !objective.isChild;
  const values = _.map(histogram, '[1]');
  const width = isParent ? 440 : 250;
  const barColors = getBarColors(objective, histogram);
  const labels = _.map(histogram, (item, index) => ({
    key: item[0],
    valueLabel: getObjectiveValueLabel(objective, item[0], barColors[index]),
    percentageLabel: getPercentageLabel(histogram, item[1])
  }));

  const labelColors = isParent ? barColors : [colors.black, colors.grey2];

  return (
    <Grid container item xs={8} md={6} direction="column">
      <Grid container item justifyContent="space-between" style={{ width }}>
        {_.map(labels, ({ key, valueLabel, percentageLabel }, index) => (
          <Grid
            item
            key={index}
            className="pointer"
            onClick={() => onClick(histogram, objective, key)}
          >
            <Flex>
              <Typography
                align="center"
                variant="S-TEXT-1"
                display="inline"
                style={{
                  color: labelColors[index],
                  margin: '0px 5px'
                }}
              >
                {valueLabel}
              </Typography>
              <Typography
                align="center"
                variant="S-TEXT-1"
                display="inline"
                style={{ color: labelColors[index] }}
              >
                {percentageLabel}
              </Typography>
            </Flex>
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <StackedProgressBar
          width={width}
          values={values}
          colors={barColors}
          showValues={false}
          className={styles.progressBar}
        />
      </Grid>
    </Grid>
  );
};

class ObjectiveStatistics extends React.Component {
  state = {
    dialogs: {
      students: {
        open: false,
        data: null
      }
    }
  };

  openStudentsModal = (scoreResponses, objective, key) =>
    this.setState({
      dialogs: {
        students: {
          open: true,
          data: { scoreResponses, objective, key }
        }
      }
    });

  closeStudentsModal = () =>
    this.setState({ dialogs: { students: { open: false, data: null } } });

  render() {
    const { dialogs } = this.state;
    const { objective, groupedStudents, groupedScoreResponses } = this.props;

    const responses = _.get(groupedScoreResponses, objective.id, []);

    const scoreResponsesHistogram = getScoreResponsesHistogram(responses);

    const objectiveHistogram = getObjectiveHistogram({
      objective,
      scoreResponsesHistogram
    });

    const objectiveShouldBeRenderedAsLabel = _.includes(
      [
        AssignmentTaskValueTypes.NUMBER,
        AssignmentTaskValueTypes.TEXT,
        AssignmentTaskValueTypes.CUSTOM
      ],
      objective.value_type
    );

    return _.isEmpty(objectiveHistogram) ? null : (
      <>
        {objectiveShouldBeRenderedAsLabel ? (
          <ObjectivePercentageLabel
            objective={objective}
            histogram={objectiveHistogram}
            onClick={this.openStudentsModal}
          />
        ) : (
          <ObjectiveProgressBar
            objective={objective}
            histogram={objectiveHistogram}
            onClick={this.openStudentsModal}
          />
        )}
        {dialogs.students.open && (
          <StudentsDialog
            data={dialogs.students.data}
            groupedStudents={groupedStudents}
            groupedScoreResponses={groupedScoreResponses}
            onClose={this.closeStudentsModal}
          />
        )}
      </>
    );
  }
}

export default ObjectiveStatistics;
