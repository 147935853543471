import React, { useContext, useState } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import EventIcon from '@material-ui/icons/Event';
import { Field } from 'formik';
import { ReactComponent as TrashIcon } from 'images/trashicon.svg';
import _ from 'lodash';

import { SearchField } from 'pages/Teachers/shared';
import { MuiAutocompleteChangeReasons } from 'utils/constants';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';

import DateTimeField from 'components/DateTimeField';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as TutorIcon } from './assets/tutor.svg';
import styles from './styles.module.scss';

const StepperAssignmentAssignForm = ({
  form,
  allSections,
  recentSections,
  remove,
  push,
  buildDefaultSection
}) => {
  const [openDatePickerSection, setOpenDatePickerSection] = useState(null);
  const [dueDatePickerSection, setDueDatePickerSection] = useState(null);

  const { user } = useContext(UserContext);
  const handleOpenButtonClick = ({ idx }) => {
    setOpenDatePickerSection(idx);
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'create',
        view: 'assign',
        button: 'open'
      }
    );
  };
  const handleDueButtonClick = ({ idx }) => {
    setDueDatePickerSection(idx);
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'create',
        view: 'assign',
        button: 'due'
      }
    );
  };

  const getAvailableSections = () => {
    const formSectionIds = _.map(form.values.sections, 'sectionId');
    return _.filter(allSections, ({ id }) => !_.includes(formSectionIds, id));
  };

  const addNewSection = (sectionId = null) => {
    const firstSection = _.head(form.values.sections);

    if (firstSection) {
      push({
        sectionId,
        open: firstSection.open,
        due: firstSection.due
      });
    } else {
      push(buildDefaultSection(sectionId));
    }
  };

  const selectRecentSections = (sections) => {
    const formSections = form.values.sections;
    const availableSectionIds = _.map(getAvailableSections(), 'id');
    const firstSection = _.first(formSections) || buildDefaultSection(null);

    _.forEach(sections, (section) => {
      if (_.includes(availableSectionIds, section.id)) {
        formSections.push({
          sectionId: section.id,
          open: firstSection.open,
          due: firstSection.due
        });
      }
    });

    form.setFieldValue(
      'sections',
      _.filter(formSections, ({ sectionId }) => !_.isNull(sectionId))
    );
  };

  const availableSections = getAvailableSections().map((section) => ({
    ...section,
    group: section.isRecent ? 'Recent' : 'All Classes'
  }));

  return (
    <div className={styles.container}>
      <Field
        component={SearchField}
        required
        fullWidth
        onChange={(e, value, reason) => {
          if (reason === MuiAutocompleteChangeReasons.SELECT_OPTION) {
            addNewSection(value.id);
          }
        }}
        className={styles.searchInput}
        options={availableSections}
        getOptionLabel={(section) => section.name}
        groupBy={(option) => option.group}
        placeholder="Add Class"
        data-intercom-target="Create Assign Classes Field"
      />

      {!_.isEmpty(recentSections) && (
        <div className={styles.recentSections}>
          <Typography variant="S-TEXT-2" className={styles.recentTitle}>
            ASSIGN RECENT:
          </Typography>
          {_.map(recentSections, (sectionSet, index) => (
            <div
              key={index}
              onClick={() => selectRecentSections(sectionSet)}
              className={styles.recentItem}
            >
              <SvgIcon fontSize="small" component={TutorIcon} />
              {_.join(_.map(sectionSet, 'name'), ', ')}
            </div>
          ))}
        </div>
      )}

      <div className={styles.selectedSections}>
        {_.map(form.values.sections, (section, idx) => {
          const currentSection = _.find(allSections, { id: section.sectionId });
          const openDateName = `sections.${idx}.open`;
          const dueDateName = `sections.${idx}.due`;

          return (
            <div className={styles.sectionRow} key={idx}>
              <div className={styles.sectionContent}>
                <Typography variant="B-Text-2" className={styles.sectionName}>
                  {currentSection?.name}
                </Typography>

                <Field
                  id={openDateName}
                  name={openDateName}
                  value={section.open}
                  onChange={(date) => form.setFieldValue(openDateName, date)}
                  error={_.has(form.errors, openDateName)}
                  helperText={_.get(form.errors, openDateName)}
                  component={DateTimeField}
                  open={openDatePickerSection === idx}
                  onClose={() => setOpenDatePickerSection(null)}
                  TextFieldComponent={({ value }) => (
                    <button
                      onClick={() => handleOpenButtonClick({ idx: idx })}
                      className={styles.dateButton}
                    >
                      <SvgIcon fontSize="small" component={EventIcon} />
                      {value ? `Open: ${value}` : 'Add Open Time'}
                    </button>
                  )}
                />

                <Field
                  id={dueDateName}
                  name={dueDateName}
                  value={section.due}
                  minDate={section.open}
                  onChange={(date) => form.setFieldValue(dueDateName, date)}
                  error={_.has(form.errors, dueDateName)}
                  helperText={_.get(form.errors, dueDateName)}
                  component={DateTimeField}
                  open={dueDatePickerSection === idx}
                  onClose={() => setDueDatePickerSection(null)}
                  TextFieldComponent={({ value }) => (
                    <button
                      onClick={() => handleDueButtonClick({ idx: idx })}
                      className={styles.dateButton}
                    >
                      <SvgIcon fontSize="small" component={EventIcon} />
                      {value ? `Due: ${value}` : 'Add Due Date'}
                    </button>
                  )}
                />
              </div>

              <button
                onClick={() => remove(idx)}
                className={styles.deleteButton}
              >
                <TrashIcon />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepperAssignmentAssignForm;
