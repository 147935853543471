import React from 'react';

import cx from 'classnames';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const STEPS = [{ name: 'Step 1' }, { name: 'Step 2' }, { name: 'Step 3' }];

const SignupStepper = ({ currentStepIndex }) => (
  <div className={styles.container}>
    {STEPS.map(({ name }, index) => {
      const isCompleted = currentStepIndex > index;
      const isActive = currentStepIndex === index;
      return (
        <div
          key={name}
          className={cx(
            styles.step,
            {
              [styles.completed]: isCompleted
            },
            {
              [styles.active]: isActive
            }
          )}
        >
          <Typography variant="S-Text-3" className={styles.name}>
            {name}
          </Typography>
          <div className={styles.prevStepConnector} />
        </div>
      );
    })}
  </div>
);

export default SignupStepper;
