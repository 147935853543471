import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  PATHWAYS_INSIGHTS_URL_WITH_ASSIGNMENT,
  TEACHERS_INSIGHTS_V2_OVERVIEW_URL
} from 'config/urls';
import { usePathwaysOverallResponseInsightOfTheDay } from 'sdk';
import { checkOverallResponseInsightOfTheDay } from 'sdk';

import InfoPanel from 'pages/Pathways/components/PathwaysDailyProgress/components/InfoPanel';
import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { notifyErrors } from 'utils/notifications';
import { useIsMobile } from 'utils/resizing';
import { reverse } from 'utils/urls';

import Legend from './components/Legend';
import Button from 'components/Button';
import UserContext from 'components/UserContext';

import DoughnutChart from '../DoughnutChart';

const HEADER_HEIGHT = 48;

const palette = [colors.pink5, colors.pink6, colors.pink7, colors.pink8];

const useStyles = makeStyles({
  headerStyle: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative'
  }),
  headerTitle: (theme) => ({
    color: '#D03673',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: '0.15px'
  }),
  headerSubtitle: (theme) => ({
    color: '#5A608D',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '17px',
    letterSpacing: '0.15px'
  }),
  contentStyle: {
    fontWeight: 700,
    color: '#CC2364',
    textAlign: 'center',
    textTransform: 'none',
    fontSize: 20,
    margin: '0 50px'
  },
  contentLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  linkButton: (theme) => ({
    color: '#5A608D',
    backgroundColor: '#fff',
    paddingLeft: 12,
    paddingRight: 8,
    '&:hover': {
      backgroundColor: theme.primaryColor
    },
    '& p': {
      fontSize: 11
    }
  }),
  buttonArrow: {
    position: 'absolute',
    color: '#F05B94',
    scale: 0.65,
    right: 16,
    marginTop: -10
  }
});

const StudentWork = ({ date }) => {
  const { data, isLoading } = usePathwaysOverallResponseInsightOfTheDay();

  const isMobile = useIsMobile();
  const user = useContext(UserContext);

  const panelTheme = {
    primaryColor: '#FFE5EE',
    secondaryColor: '#5A608D',
    accentColor: '#FFF8FA',
    headerHeight: HEADER_HEIGHT
  };
  const styles = useStyles(panelTheme);

  const checkOverallResponseInsight = async () => {
    const { success, errors } = await checkOverallResponseInsightOfTheDay({
      date
    });

    if (success) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
        {
          feature: 'pathways',
          view: 'daily progress insights',
          button: 'click'
        }
      );
    } else {
      notifyErrors(errors);
    }
  };

  if (!data && isLoading) {
    return (
      <div>
        <Skeleton variant="rect" width={'100%'} height={350} />
      </div>
    );
  }

  const overallResponse = data['overall_response'] || [];

  const dataWithColors = overallResponse.map((item, index) => ({
    ...item,
    fill: palette[index]
  }));

  return (
    <div className={styles.infoPanelContainer}>
      <InfoPanel
        theme={panelTheme}
        header={
          <div className={styles.headerStyle}>
            <div className={styles.headerTitle}>Student Work of the Day:</div>
            <div className={styles.headerSubtitle}>
              Unit {data.unit}, Lesson {data.lesson}
            </div>
          </div>
        }
        content={
          <div className={styles.contentLayout}>
            {dataWithColors.length > 0 && (
              <>
                <div>
                  <DoughnutChart data={dataWithColors} />
                </div>
                <Legend
                  items={dataWithColors.map((item) => ({
                    text: item.name,
                    color: item.fill
                  }))}
                />
              </>
            )}

            {dataWithColors.length === 0 && (
              <>
                <div className={styles.contentStyle}>
                  No recently uploaded assignments
                </div>
              </>
            )}

            <Link
              to={
                isMobile
                  ? {
                      pathname: reverse(PATHWAYS_INSIGHTS_URL_WITH_ASSIGNMENT, {
                        sectionId: data.section_id,
                        schoolId: data.school_id,
                        organizationId: data.organization_id
                      })
                    }
                  : {
                      pathname: reverse(TEACHERS_INSIGHTS_V2_OVERVIEW_URL, {
                        trackerInstanceSectionId:
                          data.tracker_instance_section_id
                      })
                    }
              }
              className={styles.noUnderline}
            >
              <Button
                variant="textAndIcon"
                fullWidth
                className={styles.linkButton}
                onClick={checkOverallResponseInsight}
                endIcon={
                  <ArrowForwardIosOutlinedIcon className={styles.buttonArrow} />
                }
              >
                View More Insights
              </Button>
            </Link>
          </div>
        }
      />
    </div>
  );
};

export default StudentWork;
