import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { TEACHERS_MEETING_FEED_URL } from 'config/urls';
import _ from 'lodash';

import { Typography } from 'pages/Walkthrough/components';
import SignupStepper from 'pages/Walkthrough/components/SignupStepper';
import { colors } from 'theme/palette';
import { parseParams, reverse } from 'utils/urls';

import Button from 'components/Button';
import UserContext from 'components/UserContext';

import { ReactComponent as CleverIcon } from './assets/clever.svg';
import { ReactComponent as CSVIcon } from './assets/csv.svg';
import { ReactComponent as GoogleIcon } from './assets/google.svg';
import styles from './styles.module.scss';

const MANUAL_CSV_ROSTERING_ENABLED =
  process.env.REACT_APP_MANUAL_CSV_ROSTERING_ENABLED === 'true';

const StudentsLoadMethodChooser = ({
  loadViaGoogleClassroom,
  loadViaClever,
  loadManuallyViaCsv,
  invitationIdentifier,
  setActiveStep
}) => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  const queryParams = parseParams(history.location.search);

  return (
    <div className={styles.loaderChooserContainer}>
      <div className={styles.stepperWrapper}>
        <Button
          color="blue"
          className={styles.controlButton}
          onClick={() => setActiveStep(0)}
        >
          Back
        </Button>
        <SignupStepper currentStepIndex={1} />
        <div className={styles.controlButton} />
      </div>
      <Typography
        style={{ fontSize: '36px', fontWeight: '700', color: colors.blue3 }}
      >
        Welcome {user.name}
      </Typography>
      <Typography
        style={{ fontSize: '20px', fontWeight: '700', color: colors.blue4 }}
      >
        Almost there
      </Typography>
      <Button
        color="blue"
        className={styles.button}
        startIcon={<GoogleIcon />}
        variant="textAndIcon"
        onClick={loadViaGoogleClassroom}
      >
        Import from Google Classroom
      </Button>
      <Button
        color="blue"
        className={styles.button}
        startIcon={<CleverIcon />}
        variant="textAndIcon"
        onClick={loadViaClever}
      >
        Clever
      </Button>
      {MANUAL_CSV_ROSTERING_ENABLED && (
        <Button
          color="blue"
          className={styles.button}
          startIcon={<CSVIcon />}
          variant="textAndIcon"
          onClick={loadManuallyViaCsv}
        >
          Upload Manually
        </Button>
      )}
      {!_.isNil(invitationIdentifier) && (
        <Button
          fullWidth
          onClick={() => {
            const invitationIdentifier = _.get(
              queryParams,
              'invitationIdentifier'
            );
            history.push({
              pathname: reverse(TEACHERS_MEETING_FEED_URL, {
                invitationIdentifier
              })
            });
          }}
          className={styles.skipButton}
        >
          Skip Loading students
        </Button>
      )}
    </div>
  );
};

export default StudentsLoadMethodChooser;
