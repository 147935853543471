import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { useHistory } from 'react-router';

import Paper from '@material-ui/core/Paper';
import { APPLE_APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from 'config/constants';
import { LOGIN_URL, MOBILE_APP_URL } from 'config/urls';
import { EDLIGHT_MAIN_DOMAIN_URL } from 'config/urls';
import { ReactComponent as AppleStoreButton } from 'images/apple-store-download.svg';
import { ReactComponent as GooglePlayButton } from 'images/google-play-download.svg';
import logo from 'images/logo-dark.svg';

import AnonymousRequired from 'pages/Login/components/AnonymousRequired';
import { Typography } from 'pages/Walkthrough/components';
import LayoutWithBackgroundImage from 'pages/Walkthrough/components/LayoutWithBackgroundImage';
import { colors } from 'theme/palette';
import { useBreakpoints } from 'utils/resizing';

import Button from 'components/Button';
import Image from 'components/Image';

import Students from './assets/students.png';
import styles from './styles.module.scss';

const StudentSignUp = () => {
  const history = useHistory();
  // const { data: user, isLoading } = useMe();

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  return (
    <LayoutWithBackgroundImage>
      <div className={styles.page}>
        {!isMobile && (
          <a href={EDLIGHT_MAIN_DOMAIN_URL}>
            <Image src={logo} className={styles.logoSmall} alt="Logo link" />
          </a>
        )}

        <div className={styles.container}>
          <Paper elevation={0} className={styles.paper}>
            <div>
              <div className={styles.content}>
                <Image
                  src={logo}
                  className={styles.logoBig}
                  alt="Logo placeholder"
                />
                <Typography
                  variant="H-TEXT-1"
                  align="center"
                  color={colors.blue3}
                  className={styles.title}
                >
                  Sign up as a student
                </Typography>

                <div className={styles.boxContainer}>
                  <div className={styles.box}>
                    <Image src={Students} className={styles.roleImage} />
                    <Typography
                      variant="B-TEXT-2"
                      align="center"
                      color={colors.blue3}
                    >
                      If your teacher already created an account for you:
                    </Typography>
                    <Button
                      color="pink"
                      className={styles.button}
                      onClick={() => history.push(LOGIN_URL)}
                    >
                      Sign in
                    </Button>
                  </div>

                  <div className={styles.box}>
                    <QRCodeSVG
                      width={187}
                      height={187}
                      color={colors.blue3}
                      value={`${window.location.origin}${MOBILE_APP_URL}`}
                    />

                    <Typography
                      align="center"
                      variant="B-Text-2"
                      color={colors.blue3}
                      className={styles.qrCodeText}
                    >
                      If your teacher asked you to download the app, grab it
                      from the App Store or Google Play and sign in there!
                    </Typography>
                    <div className={styles.appStoreLinks}>
                      <a
                        href={GOOGLE_PLAY_STORE_LINK}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GooglePlayButton className={styles.appStoreButton} />
                      </a>
                      <a
                        href={APPLE_APP_STORE_LINK}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AppleStoreButton className={styles.appStoreButton} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </LayoutWithBackgroundImage>
  );
};

export default AnonymousRequired(StudentSignUp);
