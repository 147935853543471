export const OVERALL_RESPONSE_TAB = 'Overall Responses';
export const MISCONCEPTIONS_TAB = 'Misconceptions';

export const CELEBRATE = 'Celebrate';
export const WORK_IS_COMPLETE_AND_CORRECT = 'Work is complete and correct';
export const CELEBRATE_COLOR = '#F05B94';

export const SUPPORT = 'Support';
export const GENERAL_CONCEPTUAL_UNDERSTANDING =
  'General conceptual understanding but work may be insufficient or contain some errors';
export const SUPPORT_COLOR = '#FD81BA';

export const INTERVENE = 'Intervene';
export const SIGNIFICANT_ERRORS_IN_WORK = 'Significant errors in work';
export const INTERVENE_COLOR = '#FFB0D6';

export const PENDING = 'Pending';
export const UNABLE_TO_DIAGNOSE = 'Unable to diagnose';
export const PENDING_COLOR = '#4D4D4D';

export const UNSUBMITTED_LABEL = 'Unsubmitted';
export const UNSUBMITTED_DESCRIPTION = 'Students who haven`t submitted work';
export const UNSUBMITTED_COLOR = '#757575';

export const MISCONCEPTION_OPTIONS = [
  'Computation Error (e.g., mental math errors)',
  'Precision Error (e.g., wrong units, wrong place value)',
  'Conceptual Misunderstanding (could have multiple)',
  'Representational Error (e.g., not able to draw accurate model)',
  'Insufficient Explanation (e.g., correct answer, inadequate explanation / model)',
  "Incomplete (Didn't finish / didn't answer the question)",
  "Did Not Answer Specific Question (e.g., didn't follow directions)"
];

// Data Intercom Targets
export const OVERALL_RESPONSE_TAB_INTERCOM_TARGET =
  'Insights Overall Response Tab Button';

export const MISCONCEPTIONS_TAB_INTERCOM_TARGET =
  'Insights Misconceptions Tab Button';

export const STUDENT_WORK_INTERCOM_TARGET = 'Insights Student Work Modal';

export const OVERALL_RESPONSE_CELEBRATE_COLLAPSIBLE =
  'Insights Overall Response Celebrate Collapsable';

export const OVERALL_RESPONSE_SUPPORT_COLLAPSIBLE =
  'Insights Overall Response Support Collapsable';

export const OVERALL_RESPONSE_INTERVENE_COLLAPSIBLE =
  'Insights Overall Response Intervene Collapsable';
