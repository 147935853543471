import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';

import { Link } from '@material-ui/core';
import {
  ROSTERING_ERROR_URL,
  TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL
} from 'config/urls';
import { forEach, isNil } from 'lodash';
import { uploadManualRosteringFile } from 'sdk';

import FileLine from 'pages/Teachers/BulkUpload/WorkUploadWithQR/components/FileLine';
import SignupStepper from 'pages/Walkthrough/components/SignupStepper';
import { uploadFile } from 'utils/files';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { notifyError } from 'utils/notifications';
import { stringifyParams } from 'utils/urls';

import Button from 'components/Button';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as UploadIcon } from './assets/upload.svg';
import styles from './styles.module.scss';

const ManualRostering = ({ refetchUser, selectedSchoolId, setActiveStep }) => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [fileThatIsBeingUploaded, setFileThatIsBeingUploaded] = useState(null);

  const [fileThatHasBeenUploaded, setFileThatHasBeenUploaded] = useState(null);

  const [isProcessingManualUpload, setIsProcessingManualUpload] =
    useState(false);

  const uploading = !isNil(fileThatIsBeingUploaded);
  const uploaded = !isNil(fileThatHasBeenUploaded);

  const onDrop = async (files) => {
    const file = files[0];

    if (!isNil(file)) {
      setFileThatIsBeingUploaded(file);

      const uploadedFileId = await uploadFile({
        blob: file,
        name: file.name,
        maxSize: {
          sizeInBytes: 100 * 1_048_576,
          sizeAsString: '100MB'
        },
        trackMixpanelEventOnFailure: true
      });

      if (isNil(uploadedFileId)) {
        setFileThatIsBeingUploaded(null);
      } else {
        file.uploadedFileId = uploadedFileId;

        setFileThatHasBeenUploaded(file);
      }
    }

    setFileThatIsBeingUploaded(null);
  };

  const onFileSubmit = async () => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'roster sync',
        view: 'complete',
        roster_type: 'manual'
      }
    );
    setIsProcessingManualUpload(true);

    const data = { file: fileThatHasBeenUploaded.uploadedFileId };

    const { success } = await uploadManualRosteringFile({ data });

    refetchUser();

    setIsProcessingManualUpload(false);

    if (success) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.FORM} ${MixpanelAction.ON_CHANGE}`,
        {
          feature: 'roster sync',
          view: 'success',
          roster_type: 'manual'
        }
      );
      history.push(TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL);
    } else {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.FORM} ${MixpanelAction.ON_CHANGE}`,
        {
          feature: 'roster sync',
          view: 'fail',
          roster_type: 'manual'
        }
      );
      history.push({
        pathname: ROSTERING_ERROR_URL,
        search: stringifyParams({ manual: true, schoolId: selectedSchoolId })
      });
    }
  };

  const onDropRejected = (files) => {
    return forEach(files, () => notifyError('Unsupported file type.'));
  };

  const handleDeleteFile = () => {
    setFileThatHasBeenUploaded(null);
  };

  const { getInputProps, open } = useDropzone({
    onDrop,
    accept: '.csv',
    onDropRejected
  });

  return (
    <div>
      <div className={styles.stepperWrapper}>
        <Button
          color="blue"
          className={styles.controlButton}
          onClick={() => setActiveStep(1)}
        >
          Back
        </Button>
        <SignupStepper currentStepIndex={1} />
        <Button
          color="pink"
          className={styles.controlButton}
          disabled={!uploaded || isProcessingManualUpload}
          onClick={onFileSubmit}
        >
          Upload
        </Button>
      </div>
      <div className={styles.container}>
        <Typography className={styles.title}>
          Follow these steps to upload your roster manually
        </Typography>
        <div className={styles.container}>
          <Typography className={styles.step}>
            <span className={styles.stepNumber}>Step 1</span>{' '}
            <span>
              Download our CSV{' '}
              <a
                className={styles.templateLink}
                href="/files/roster_template.csv"
                download="roster_template.csv"
              >
                template
              </a>
              .
            </span>
          </Typography>
          <Typography className={styles.step}>
            <span className={styles.stepNumber}>Step 2</span>{' '}
            <span>
              Check our Upload {''}
              <Link
                className={styles.templateLink}
                target="_blank"
                href="https://docs.google.com/presentation/d/1JEUXt_C-4xnQr3tUdiuOGg7ibEEjHIS1DfgdEn1tY7s/edit#slide=id.g33c77814efa_0_71"
              >
                Instructions
              </Link>
              .
            </span>
          </Typography>
          <Typography className={styles.step}>
            <span className={styles.stepNumber}>Step 3</span>{' '}
            <span>Add your class(es) details and save the file.</span>
          </Typography>
          <Typography className={styles.step}>
            <span className={styles.stepNumber}>Step 4</span>{' '}
            <span>
              Come back and upload the CSV here. We`ll set your class up for
              you!
            </span>
          </Typography>

          <Button onClick={open} color="blue" fullWidth>
            <input {...getInputProps()} />
            <UploadIcon /> UPLOAD FILE
          </Button>

          {uploading && <FileLine file={fileThatIsBeingUploaded} user={user} />}
          {uploaded && (
            <FileLine
              file={fileThatHasBeenUploaded}
              user={user}
              showProgress={isProcessingManualUpload}
              handleDeleteFile={handleDeleteFile}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualRostering;
