import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { ReactComponent as HashtagIcon } from 'pages/Teachers/shared/AssignmentDialog/assets/hashtag.svg';
import {
  AssignmentDepthOptions,
  AssignmentTaskValueTypes
} from 'utils/constants';

export const EMPTY_TASK = {
  name: '',
  value_type: AssignmentTaskValueTypes.RUBRIC_ZERO_FOUR,
  depth: AssignmentDepthOptions.TASK,
  max_value: 0,
  description: '',
  children: [],
  standards: []
};

export const FIRST_PAGE = {
  ...EMPTY_TASK,
  name: 'Page 1'
};

export const EMPTY_CRITERIA = {
  name: '',
  value_type: AssignmentTaskValueTypes.RUBRIC_ZERO_FOUR,
  depth: AssignmentDepthOptions.CRITERIA,
  max_value: 0,
  description: ''
};

export const VALUES_OPTIONS = [
  {
    key: 'checkbox',
    Icon: CheckBoxOutlinedIcon,
    text: 'Correct/Incorrect'
  },
  {
    key: 'check_grade',
    Icon: CheckIcon,
    text: 'Check grade'
  },
  {
    key: 'rubric_zero_four',
    Icon: FormatListNumberedIcon,
    text: '0-4 Rubric'
  },
  {
    key: 'rubric_a_to_f',
    Icon: FormatListNumberedIcon,
    text: 'A-F Rubric'
  },
  {
    key: 'number',
    Icon: HashtagIcon,
    text: 'Number'
  },
  {
    key: 'custom',
    Icon: FormatListNumberedIcon,
    text: 'Custom'
  }
];
